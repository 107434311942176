<script lang="ts" >
  import { onMount } from 'svelte'
  import page from 'page'
  import { userStore, IUser } from '../../routes/users/stores/UserStore'
  import Dropdown from '../../common/dropdown/components/Dropdown.svelte'
  import { onSubscribe } from '../../lib/helpers'
  import Alerts from '../../common/alert/components/Alert.svelte'
  import { alertStore } from '../../common/alert/stores/AlertStore'
  import { coinStore, ICoin } from '../../routes/coins/stores/CoinStore'

  import { events } from '../../lib/Events'
  import { modalStore } from '../../common/modal/stores/ModalStore'
  import Support from './Support.svelte'
  import Config from '../../lib/Config';

  onSubscribe(userStore, onSignOutSuccess, 'signOut.success')
  onSubscribe(userStore, onSignInSuccess, ['signIn.success', 'signUp.success', 'resetPassword.success', 'loadToken.success'])
  onSubscribe(coinStore, onCoinFindTallySuccess, 'findTally.success')
  onSubscribe(coinStore, onCoinFindSubscriptionSuccess, 'findSubscription.success')

  let self: IUser
  let hasAdmin = false
  let hasProxy = false
  let tally = 0
  let subscription: Partial<ICoin>
  let subscribeLabel = 'Subscribe'

  onMount(() => {
    userStore.loadToken()
    events.subscribe('userStore.session.error', (error) => {
      alertStore.add({message: 'Session not active. Please sign in to continue.', type: 'danger'})
      userStore.signOut()
    })
    events.subscribe('userStore.active.error', (error) => {
      page('/users/confirm')
    })
    events.subscribe('alertStore.error', (error) => {
      alertStore.add({message: error.detail.message, type: 'danger'})
    })
  })

  function onCoinFindTallySuccess() {
    tally = coinStore.get().tally
  }

  function onCoinFindSubscriptionSuccess() {
    subscription = coinStore.get().subscription
    subscribeLabel = subscription?._id ? 'Update Subscription' : 'Subscribe'
  }

  function onSignInSuccess() {
    self = userStore.get().self
    hasAdmin = userStore.hasAuth('admin')
    hasProxy = userStore.hasAuth('proxy')
  }

  function onSignOutSuccess() {
    page('/users/signin')
    location.reload()
  }

  function handleSignOut(){
    userStore.signOut()
  }
  
</script>

<Alerts />
<Support />
<nav class={hasProxy ? 'navbar navbar-expand-lg navbar-dark bg-danger': 'navbar navbar-expand-lg navbar-dark bg-dark'}>
  <div class="container">
    <div class="navbar-header">
      <a class="navbar-brand" href="/">
      <img class="logo" alt="SEO Arcade" src="/images/logo.png" />
      </a>
    </div>
    {#if !self?._id}
      <ul class="nav navbar-nav">
        <li>
        <button class="btn btn-default" on:click={() => page('/users/signin')}>Sign In</button>
        <button class="btn btn-primary margin-left-sm" on:click={() => page('/users/signup')}>Sign Up</button>
        </li>
      </ul>
    {:else}
      <ul class="nav navbar-nav main-menu">
        <li class="coins">
          <button class="btn" on:click={() => page('/coins')}>
            {tally == Number(Config.get('pricing.unlimited')) ? '∞' :  tally} coins
          </button>
        </li>
        <li>
          <Dropdown
            trigger={{label: self.firstName}}
            links={[
            {label: 'Seo Roi Forecasts', to: '/'},
            {label: subscribeLabel , to: '/coins'},
            {label: 'Click Through Rates', to: '/clickThroughRates'},
            {label: 'Brand', to: '/brand'},
            {label: 'Account', to: '/users/account'},
            {label: 'Support', onClick: () => modalStore.show('Support')},
            ...(() => hasAdmin && !hasProxy ? [
                {divider: true},
                {label: 'Admin', to: '/admin'}
              ] : [])(),
              
            {divider: true},
            
            {label: 'Sign Out', onClick: handleSignOut}
            ]}
          />
        </li>
      </ul>
    {/if}
  </div>
</nav>

<style>
  .logo {
    height: 32px;
  }

  .navbar {
    padding-top: 0;
    padding-bottom: 20px;
  }

  .navbar .btn {
    color: #fff;
  }

  .navbar-brand {
    font-size: 30px;
  }

  .navbar-header {
    margin-top: 24px;
  }

  .navbar-brand > img {
    display: inline;
  }

  .navbar-header {
    float: left;
  }

  .navbar-nav {
    float: right !important;
    margin: 0;
    margin-top: 20px;
  }

  .navbar-nav .coins button {
    margin: 0px 16px 0 0;
    color: #fff;
    font-size: 1.5em;
    text-transform: lowercase;
  }

  /*
  * Media KEEP AT BOTTOM!
  */

  @media (max-width: 768px){
    .container {
      padding-left: 5px;
      padding-right: 5px;
    }
    .logo {
      height: 35px;
    }
    .navbar-brand{
      font-size: 25px;
      line-height: 25px;
      padding: 14px 20px;
    }
  }
</style>