import page from 'page';
import Store from '../../../lib/Store';
class AlertStore extends Store {
    constructor() {
        super({ alerts: [] });
        page.exit(this.onPageExit.bind(this));
    }
    /**
     * On Page Exit
     */
    onPageExit(ctx, next) {
        // Any persisted alerts need to go now.
        this.get().alerts.forEach(alert => {
            if (!alert.timeout) {
                alert.timeout = 4000;
            }
        });
        this.clear();
        next();
    }
    /**
     * Add
     */
    add(addAlerts) {
        const alerts = [];
        if (!Array.isArray(addAlerts))
            addAlerts = [addAlerts];
        const messages = [];
        addAlerts.forEach(alert => {
            alert.type = alert.type || 'info';
            // Check for duplicates unless duplicates are allowed
            if (alert.duplicates || !messages.includes(alert.message)) {
                messages.push(alert.message);
                alerts.push(alert);
            }
        });
        setTimeout(() => {
            this.set({ alerts }, 'add.success');
        }, 500);
        setTimeout(() => {
            alerts.forEach(({ message }) => {
                this.delete(message);
            });
        }, 10000);
    }
    /**
     * Delete
     */
    delete(message) {
        this.set({ alerts: this.get().alerts.filter(a => a.message != message) }, 'delete.success');
    }
    /**
     * Clear
     */
    clear() {
        this.set(this.default, 'cleared.success');
    }
}
export const alertStore = new AlertStore();
