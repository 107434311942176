
<script lang="ts">
  import { coinStore } from '../stores/CoinStore'
  import Modal from '../../../common/modal/components/Modal.svelte'

  export let quantity: number = 1000

  $: form.quantity = quantity

  const form = {
		quantity: 1000
	}

  let quantities: number[] = []
  for(let count = 1000; count <= 10000; count = count + 1000) {
		quantities.push(count)
  }

  function handleSubmit() {
    coinStore.grant(form.quantity/1000)
  }

</script>

<Modal id="CoinGrant" title="Grant Coins" >

  <form on:submit|preventDefault={handleSubmit}>
    <div class="row row-cols-auto mt-2">
      <div class="col col-lg-6">
        <div class="form-group mt-2">
          <label class="form-label" for="quantity">Quantity</label>
          <select class="form-control form-select" bind:value={form.quantity}>
            {#each quantities as quantity}
              <option value={quantity}>{quantity}</option>
            {/each}
          </select>
        </div>
      </div>
      
    </div>
    <div class="row row-cols-auto mt-4">
      <div class="col col-lg-6">
        <button type="submit" class="btn btn-primary">Grant {form.quantity} coins valuing ${form.quantity/100}</button>
      </div>
    </div>

  </form>
  
</Modal>
