<footer class="bg-dark text-center text-white">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <ul class="nav navbar-nav">
        <li>
          <a href="https://seoarcade.com/keyword-research-pricing/" target="_blank">Terms and Conditions</a>
        </li>
      </ul>
      <ul class="nav navbar-nav float-end">
        <li>
          © {(new Date()).getFullYear()} Copyright:
          <a href="https:/seoarcade.com/" target="_blank">seoarcade.com</a>
        </li>
      </ul>
    </div>
  </nav>

</footer>

<style>
  footer {
    margin-top: auto;
  }
  a {
    color: #fff;
  }
  .navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
</style>