<script lang="ts">
  import { onMount, afterUpdate, SvelteComponent } from 'svelte' 
  import { onSubscribe } from '../../../lib/helpers'
  import { tableStore } from '../stores/TableStore'
  import TablePagination from './TablePagination.svelte'
  import TableLimit from './TableLimit.svelte'
  import TableSearch from './TableSearch.svelte'
  import TableRow from './TableRow.svelte'
  import TableHeader from './TableHeader.svelte'
  import type { IColumn, ICollation } from './types'
import { alertStore } from '../../alert/stores/AlertStore';

  let start: number = 0
  let limit: number = 20
  let total: number = 0
  let page: number = 1
  let pages: number = 0
  let searchBy: string = ''
  let searchValue: string = ''
  let pageInfo: string = 'no results'
  let show: boolean = true
  let rows = []

  export let id: string = ''
  export let query: string = ''
  export let variables: Record<string, any> = {}
  export let emptyResults: string = 'No results found'
  export let columns: IColumn[] = []
  export let callbacks: any = {}
  export let sortBy: string = columns[0].id
  export let sortDirection: number = 1
  export let title: string = ''
  export let manualRefresh: boolean = false
  export let showHead: boolean = true
  export let showPagination: boolean = true
  export let showSearch: boolean = true
  export let showEmpty: boolean = true
  export let searchPlaceholder: string = 'Search'
  export let rowComponent: typeof SvelteComponent = undefined
  
  onSubscribe(tableStore, onDataSuccess, [`getData.success.${id}`, `setData.success.${id}`])
  onSubscribe(tableStore, onRefresh, `refresh.success.${id}`)

  onMount(() => {
    loadSettings()
    loadData(true)
  })

  afterUpdate(() => {
    const key = `${id}.table.settings`
	  localStorage.setItem(key, JSON.stringify({
      limit, page, sortBy, sortDirection, searchBy, searchValue
    }))
  })

  function onRefresh() {
    loadData(true)
  }

  function loadSettings() {
    const key = `${id}.table.settings`
    const settingsObjStorage = localStorage.getItem(key)
    let settingsObj: ICollation = {}
    if(settingsObjStorage) {
      try {
        settingsObj = JSON.parse(settingsObjStorage) as ICollation
      } catch(err) {
        settingsObj = {}
      }
    } else {
      settingsObj = {}
    }
    limit = settingsObj.limit || limit
    page = settingsObj.page || page
    sortBy = settingsObj.sortBy || sortBy
    sortDirection = settingsObj.sortDirection || sortDirection
    searchBy = settingsObj.searchBy || searchBy
    searchValue = settingsObj.searchValue || searchValue

  }

  function loadData(findTotal: boolean = false) {
    const collation = { limit: limit, skip: (page - 1) * limit, sortBy, sortDirection, searchBy, searchValue}
    tableStore.loadData({ 
      id,
      query: findTotal ? query.replace('<<total', '').replace('total>>', '') : query.replace(query.match(/<<total(.*)total>>/m)[0], ''),
      variables: {...variables, collation} 
    })
  }

  function onDataSuccess(){
    const data = tableStore.getData(id)
    rows = onData(data.rows)
    total = data.total
    show = !showEmpty && total === 0 ? false : true
    setPageInfo()
  }

  function onData<T>(r): T {

    if (callbacks.onData) {
      r = callbacks.onData(r)
    }

    return r
  }

  function handleLimitChange(e: InputEvent) {
    
    limit = parseInt((e.target as HTMLInputElement).value)
    start = 0
    page = 1
    loadData()
  }

  function handleSearchChange(by, value) {
    searchBy = by
    searchValue = value
    start = 0
    page = 1
    loadData(true)

  }

  function setPageInfo() {

    pages = Math.ceil(total / limit)

    if(start < 0) start = 0

    page = start > 0 ? ((start / limit) + 1) : 1

    let indexFirst = start + 1
    let indexLast = start + limit
    indexLast = total < indexLast ? total : indexLast
    pageInfo = `${indexFirst}-${indexLast} of ${total}`
  }

  function handlePageChange(e, direction) {

    switch(direction) {
      case 'first':
        start = 0
        break
      case 'previous':
        start = start - limit
        break
      case 'next':
        start = start + limit
        break
      case 'last':
        start = (pages - 1) * limit
        break
    }

    setPageInfo()
    loadData()
  }

  function handleSortChange(by: string, direction: number) {
    sortBy = by
    sortDirection = direction

    loadData()
  }

  function handleManualRefresh() {
    alertStore.add({ message: 'Table refreshed.'})
    onRefresh()
  }

</script>


{#if show}
  <div class="table-component mt-4 mb-2">
    <div class="d-flex row row-cols-auto">
      
      {#if title}
        <div class="col flex-grow-1">
          <h2 class="title inline">{title} {#if manualRefresh}<button type="button" on:click={handleManualRefresh} class="manual-refresh btn">↻</button>{/if}</h2>
        </div>
      {/if}
    
      {#if showSearch}
        <div class="col search-top" >
          <TableSearch
            value={searchValue}
            searchPlaceholder={searchPlaceholder}
            columns={columns}
            onChange={handleSearchChange}
          />
        </div>
      {/if}
      {#if showPagination}
        <div class="col">
          <TableLimit
            onChange={handleLimitChange}
            limit={limit}
          />
        </div>
      {/if}
    </div>

    <div class="container">
      <table
        id={id}
      >
        {#if showHead}
          <thead>
            <tr>
              {#each columns as column}
                <TableHeader 
                  column={column}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  onChange={handleSortChange}
                />
              {/each}
            </tr>
          </thead>
        {/if}
        <tbody>
          {#if rows.length}
            {#each rows as row, index}
              <TableRow
                index={index}
                row={row}
                columns={columns}
                component={rowComponent}
              />
            {/each}
          {:else}
            <tr><td colSpan={columns.length}>{emptyResults}</td></tr>
          {/if}
        </tbody>
      </table>

    </div>
    <div class="d-flex row row-cols-auto">

      {#if showPagination}
        <div class="col flex-grow-1 mt-1" >
          <TablePagination
            pageInfo={pageInfo}
            onChange={handlePageChange}
            page={page}
            pages={pages}
          />
        </div>
        
      {/if}
      {#if showSearch}
        <div class="col search-bottom mt-1" >
          <TableSearch
            value={searchValue}
            searchPlaceholder={searchPlaceholder}
            columns={columns}
            onChange={handleSearchChange}
          />
        </div>
      {/if}

    </div>
    
  </div>
{/if}

<style>

  table {
    table-layout: fixed;
    border: none;
  }
  .container {
    border: 2px solid rgb(145 154 161);
    padding: 1em;
    margin-top: 1em;
    overflow-x: scroll;
  }

  .manual-refresh {
    font-size: 0.8em !important;
  }

  table thead {
    border: none;
  }

  table tbody {
    border: none;
  }

  @media screen and (min-width: 39.9375em) {
    .search-bottom {
      display: none;
    }
  }

  @media screen and (max-width: 39.9375em) {

    .search-top {
      display: none;
    }

    table thead {
      display: none;
    }

  }
</style>