import { writable, get } from 'svelte/store';
import { clone } from '../lib/helpers';
import { errors } from './Errors';
import Config from './Config';
/**
 * Store
 */
export default class Store {
    constructor(state) {
        this.default = state;
        this.store = writable(clone(state));
    }
    /**
     * Set Store
     */
    reset() {
        this.set(this.default, 'reset.success');
    }
    /**
     * Subscribe
     */
    subscribe(run, actions = []) {
        return this.store.subscribe(value => {
            if (!actions.length || actions.includes(value['__action']))
                run(value);
        });
    }
    /**
     * Set Store
     */
    set(value, action) {
        value['__action'] = action;
        this.store.set(value);
    }
    /**
     * Publish
     */
    publish(action) {
        this.update(s => (Object.assign({}, s)), action);
    }
    /**
     * Get Store
     */
    get() {
        return get(this.store);
    }
    /**
     * Update Store
     */
    update(updater, action) {
        this.store.update(value => {
            value['__action'] = action;
            return updater(value);
        });
    }
    /**
     * Request
     * Simple request
     */
    async graphql(query, variables = {}) {
        var _a;
        const response = await fetch(`${Config.get('domain')}/graphql`, {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ query, variables })
        });
        const jsonResponse = await response.json();
        if ((_a = jsonResponse === null || jsonResponse === void 0 ? void 0 : jsonResponse.errors) === null || _a === void 0 ? void 0 : _a.length) {
            errors.add(jsonResponse === null || jsonResponse === void 0 ? void 0 : jsonResponse.errors);
        }
        return jsonResponse;
    }
}
