import { onDestroy } from 'svelte';
export function base64Decode(str) {
    var output = str.replace('-', '+').replace('_', '/');
    switch (output.length % 4) {
        case 0:
            break;
        case 2:
            output += '==';
            break;
        case 3:
            output += '=';
            break;
    }
    return window.atob(output);
}
export function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
export function onSubscribe(instance, fn, actions = []) {
    onDestroy(instance.subscribe(fn, Array.isArray(actions) ? actions : [actions]));
}
export function getQueryParam(param) {
    const queryStr = unescape(window.location.search) + '&';
    const regex = new RegExp('.*?[&\\?]' + param + '=(.*?)&.*');
    const val = queryStr.replace(regex, "$1");
    return val == queryStr ? null : val;
}
export function toDecimal(n, places = 2) {
    return +(Math.round(n + `e+${2}`) + `e-${2}`);
}
export function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}
export function parseCsv(csv = '') {
    csv = typeof csv === 'string' ? csv : '';
    return csv.replace(new RegExp('\n', 'g'), '').split(',').map(k => k.trim()).filter(k => k);
}
export function formatCsv(csv = '') {
    return parseCsv(csv).join(', ');
}
export function clone(obj) {
    return JSON.parse(JSON.stringify(obj));
}
