
<script lang="ts">
  import Modal from '../../../common/modal/components/Modal.svelte'
  import { modalStore } from '../../../common/modal/stores/ModalStore'

  export let reportLocation = ''
  export let reportStatus = 'generating'

  function handleViewReport(copy = false) {
    modalStore.hide()
  }


</script>

<Modal id="SeoRoiForecastReport" title="View or Copy Report" width="660px">

  {#if reportStatus == 'generating'}
    <div class="row row-cols-auto">
      <div class="col-12 mt-2">
        The report is still generating. It can take up to 1-2 minutes to complete. The table is refreshing every few seconds so please look for the status to change to complete.
      </div>
    </div>
  {:else}
    <div class="row row-cols-auto">
      <div class="col-12 mt-2">
        The orginal (read only) report was securly saved to SEOArcade's Google Docs account. To edit the report, click copy below; this will copy an editable version to your Google Docs account.
      </div>
    </div>
    <div class="row row-cols-auto">
      <div class="d-flex justify-content-center col-12 mt-3">
        <a class="btn btn-link" href={`${reportLocation}/edit`} target="_blank" on:click={() => handleViewReport()}>View Ready Only Original</a> <a class="ms-2 btn btn-primary" href={`${reportLocation}/copy`} target="_blank" on:click={() => handleViewReport(true)}>Copy and Edit Report</a>
      </div>
    </div>
  {/if}


  
  
</Modal>
