<script lang='ts'>
    import type { IColumn } from './types'
    import Tooltip from '../../../common/tooltip/Tooltip.svelte'

    interface IParam {
      id: string
      label: string
      to?: string
    }
  
    export let row: Record<string, any>
    export let cell: IColumn
    $: stringLength = row[cell.id].length
  </script>
  
  <td class={cell.class || ''} id={cell.id} width={cell.width || ''}>
    {#if stringLength > 20}
        {row[cell.id].substring(0, 20) + '...'}
        <Tooltip symbol="popout" >{row[cell.id]}</Tooltip>
    {:else}
        {row[cell.id]}
    {/if}
  </td>
  
  <style>
    td {
      padding: 1rem .625rem 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: 0.5s;
      border-top: 1px solid #ddd;
    }
  
    @media screen and (min-width: 39.9375em) {
      .thead {
        display: none;
      }
    }
  
    @media screen and (max-width: 39.9375em) {
  
      .thead {
        text-align: center;
      }
      td {
        display: block;
        width: 400px;
        text-align: center;
      }
    }
  </style>