<script lang="ts">
  import { onMount } from 'svelte'
  import Config from '../../../lib/Config'
  import Table from '../../../common/table/components/Table.svelte';
  import TableLinks from '../../../common/table/components/TableLinks.svelte'
  import { onSubscribe } from '../../../lib/helpers'
  import { userStore } from '../../users/stores/UserStore'
  import CoinCheckout from '../modals/CoinCheckout.svelte';
  import CoinGrant from '../modals/CoinGrant.svelte';
  import { coinStore, ICoin } from '../stores/CoinStore'
  import { tableStore } from '../../../common/table/stores/TableStore'
  import { modalStore } from '../../../common/modal/stores/ModalStore'
  import { alertStore } from '../../../common/alert/stores/AlertStore'

  let hasAdmin = false
  let tally = 0
  let subscription: Partial<ICoin>


  onMount(() => {
    userStore.validateAuth()
    coinStore.findTally()
    coinStore.findSubscription()
    hasAdmin = userStore.hasAuth('admin')
  })

  onSubscribe(coinStore, onCoinCheckoutSuccess, 'checkout.success')
  onSubscribe(coinStore, onCoinGrantSuccess, 'grant.success')
  onSubscribe(coinStore, onCoinUpdateSubscriptionSuccess, 'updateSubscription.success')
  onSubscribe(coinStore, onCoinFindTallySuccess, 'findTally.success')
  onSubscribe(coinStore, onCoinFindSubscriptionSuccess, 'findSubscription.success')

  function onCoinFindTallySuccess() {
    tally = coinStore.get().tally
  }

  function onCoinFindSubscriptionSuccess() {
    subscription = coinStore.get().subscription
  }

  function onCoinCheckoutSuccess() {
    window.location.href = coinStore.get().checkoutUrl
  }

  function onCoinGrantSuccess() {
    alertStore.add({message: 'Coins granted'})
    modalStore.hide()
    tableStore.refresh('coins')
  }

  function onCoinUpdateSubscriptionSuccess() {
    alertStore.add({ message: 'Subscription will be canceled at end of subscription cycle.' })
    modalStore.hide()
  }


</script>

<main>
  <CoinCheckout />
  {#if hasAdmin}
    <CoinGrant />
  {/if}

  <h1>
    <div class="row">
      <div class="col col-lg-6">
        Coins
      </div>

      <div class="col col-lg-6">
        <button type="button" class="float-lg-end btn btn-primary" on:click={() => modalStore.show('CoinCheckout')}>
          {#if subscription?._id}
            Update Subscription
          {:else}
            Subscribe
          {/if}
        </button>
        
        {#if hasAdmin}
          <button type="button" class="float-lg-end btn btn-primary me-2" on:click={() => modalStore.show('CoinGrant')}>Grant Coins</button>
        {/if}
      </div>

      
    </div>
  </h1>

  <div class="row">
    <div class="col col-lg-6">
      <div class="pocket" >
        {#if tally == Number(Config.get('pricing.unlimited'))}
          ∞ coins in your pocket
        {:else}
          {tally} coins in your pocket
        {/if}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col mt-4">
      <Table
        id="coins"
        title="Coin Events "
        sortBy="createdAt"
        sortDirection={-1}
        columns={[
          { id: 'createdAt', width: '130', title: 'Date', format: 'date', sortable: true},
          { id: 'tally', width: '150', title: 'Tally', sortable: true },
          { id: 'amount', width: '150', title: 'Amount', sortable: true },
          { id: 'product', width: '200', title: 'Product', sortable: true },
          { id: 'units', width: '150', title: 'Units', sortable: true },
          { id: 'quantity', width: '150', title: 'Quantity', sortable: true },
          { id: 'eventSource', width: '150', title: 'Event', sortable: true,  },
          { id: 'receiptUrl', width: '150', title: 'Receipt', component: TableLinks, componentParams: { links: [ { id: 'receiptUrl', label: 'View'}]}
        },

        ]}
        query={`
          query coins($collation: Collation!){ 
            coins(collation: $collation ) {
              _id
              tally
              amount
              product
              units
              quantity
              eventSource
              receiptUrl
              createdAt
            }
            <<total coinsTotal(collation: $collation) total>> 
          }`
        }
      />
    </div>
  </div>
</main>

<style>
  .pocket {
    padding: 20px;
    color: #000;
    font-size: 1.5em;
    border: 2px solid rgb(145 154 161);
  }
</style>