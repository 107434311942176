import Store from '../../../lib/Store';
import { base64Decode } from '../../../lib/helpers';
import { alertStore } from '../../../common/alert/stores/AlertStore';
class UserStore extends Store {
    constructor() {
        super({
            self: {
                _id: '',
                email: '',
                firstName: '',
                lastName: '',
                password: '',
                salt: '',
                auth: ['user'],
                active: false,
                promotionalEmail: true,
                createdAt: 0,
                updatedAt: 0,
                deletedAt: 0
            }
        });
    }
    /**
     * Get Self From Token
     */
    getSelfFromToken() {
        const token = localStorage.getItem('token');
        if (token)
            return JSON.parse(base64Decode(token.split('.')[1]));
        return this.default.self;
    }
    /**
     * Self
     */
    async self() {
        const { data } = await this.graphql('query { userSelf { _id, email, firstName, lastName, auth, active, promotionalEmail, createdAt, updatedAt, deletedAt }}');
        if (data)
            this.set({ self: data.userSelf }, 'self.success');
        else
            alertStore.add({ message: 'Session not active.' });
    }
    /**
     * Load Token
     */
    loadToken() {
        const self = this.getSelfFromToken();
        this.set({ self }, 'loadToken.success');
    }
    /**
     * Validate Auth
     */
    validateAuth(auth = 'user') {
        if (!this.get().self._id || !this.hasAuth(auth)) {
            this.signOut();
        }
    }
    /**
     * has Auth
     */
    hasAuth(auth) {
        const self = this.get().self;
        return self.auth.some(a => a.startsWith(auth));
    }
    /**
     * Update
     */
    async selfUpdate({ firstName, lastName, email, password, promotionalEmail }) {
        var _a;
        const self = this.get().self;
        const { data } = await this.graphql(`mutation userSelfUpdate($firstName: NonEmptyString!, $lastName: NonEmptyString!, $email: Email!, $password: Password, $promotionalEmail: Boolean)
      { userSelfUpdate(user: { firstName: $firstName, lastName: $lastName, email: $email, password: $password, promotionalEmail: $promotionalEmail}) { token }}
    `, { firstName: firstName || self.firstName,
            lastName: lastName || self.lastName,
            email: email || self.email,
            promotionalEmail: promotionalEmail || self.promotionalEmail,
            password
        });
        if ((_a = data === null || data === void 0 ? void 0 : data.userSelfUpdate) === null || _a === void 0 ? void 0 : _a.token) {
            localStorage.setItem('token', data.userSelfUpdate.token);
            const self = this.getSelfFromToken();
            this.set({ self }, 'selfUpdate.success');
        }
    }
    /**
     * ResetPassword
     */
    async resetPassword({ password }) {
        var _a;
        const token = (new URLSearchParams(window.location.search)).get('token');
        localStorage.setItem('token', token);
        const { data } = await this.graphql(`mutation userSelfUpdate($password: Password)
      { userSelfUpdate(user: { password: $password}) { token }}
    `, { password });
        if ((_a = data === null || data === void 0 ? void 0 : data.userSelfUpdate) === null || _a === void 0 ? void 0 : _a.token) {
            localStorage.setItem('token', data.userSelfUpdate.token);
            const self = this.getSelfFromToken();
            this.set({ self }, 'resetPassword.success');
        }
    }
    /**
     * Sign Up
     */
    async signUp({ firstName, lastName, email, password }) {
        var _a;
        const { data } = await this.graphql(`mutation userSignUp($firstName: NonEmptyString!, $lastName: NonEmptyString!, $email: Email!, $password: Password!)
      { userSignUp(user: { firstName: $firstName, lastName: $lastName, email: $email, password: $password}) { _id } }
    `, { firstName, lastName, email, password });
        if ((_a = data === null || data === void 0 ? void 0 : data.userSignUp) === null || _a === void 0 ? void 0 : _a._id) {
            if (window.ga) {
                ;
                window.ga('send', 'event', { eventCategory: 'App Signup', eventAction: 'create Account', eventLabel: 'signup' });
            }
            this.publish('signUp.success');
        }
    }
    /**
     * Sign Out
     */
    signOut() {
        localStorage.removeItem('token');
        this.set(this.default, 'signOut.success');
    }
    /**
     * Sign In
     */
    async signIn({ email, password }) {
        var _a;
        const { data, errors } = await this.graphql('mutation userSignIn($email: Email!, $password: Password!){ userSignIn(user: { email: $email, password: $password}) { token }} ', { email, password });
        if ((_a = data === null || data === void 0 ? void 0 : data.userSignIn) === null || _a === void 0 ? void 0 : _a.token) {
            localStorage.setItem('token', data.userSignIn.token);
            const self = this.getSelfFromToken();
            this.set({ self }, 'signIn.success');
        }
        else if (errors[0].message === 'User not active.') {
            page('/users/confirm');
            this.publish('signIn.userNotActive');
        }
    }
    /**
     * Proxy
     */
    async proxy(_id) {
        var _a;
        const { data } = await this.graphql(`mutation { userProxy(_id: "${_id}") { token }}`);
        if ((_a = data === null || data === void 0 ? void 0 : data.userProxy) === null || _a === void 0 ? void 0 : _a.token) {
            localStorage.setItem('token', data.userProxy.token);
            const self = this.getSelfFromToken();
            this.set({ self }, 'proxy.success');
        }
    }
    /**
     * Forgot Password
     */
    async forgotPassword({ email }) {
        var _a;
        const { data } = await this.graphql('mutation userForgotPassword($email: Email!){ userForgotPassword(user: { email: $email}) { message }} ', { email });
        if ((_a = data === null || data === void 0 ? void 0 : data.userForgotPassword) === null || _a === void 0 ? void 0 : _a.message)
            alertStore.add({ message: data.userForgotPassword.message });
    }
    /**
     * Unsubscribe
     */
    async unsubscribe() {
        const self = this.getSelfFromToken();
        const params = new URLSearchParams(window.location.search);
        const { data } = await this.graphql('mutation userUnsubscribe($_id: ID!, $email: Email!){ userUnsubscribe(user: { _id: $_id, email: $email })} ', { email: self.email || params.get('email'), _id: self._id || params.get('_id') });
        if (data === null || data === void 0 ? void 0 : data.userUnsubscribe) {
            this.update(state => {
                state.self.email = state.self.email || (data === null || data === void 0 ? void 0 : data.userUnsubscribe);
                return state;
            }, 'unsubscribe.success');
        }
    }
    /**
     * Confirm
     */
    async confirm(token) {
        var _a, _b;
        localStorage.setItem('token', token);
        const { data } = await this.graphql(`mutation { userConfirm { token }}`);
        if ((_a = data === null || data === void 0 ? void 0 : data.userConfirm) === null || _a === void 0 ? void 0 : _a.token) {
            localStorage.setItem('token', (_b = data === null || data === void 0 ? void 0 : data.userConfirm) === null || _b === void 0 ? void 0 : _b.token);
            this.loadToken();
            this.publish('confirm.success');
        }
        else {
            this.publish('confirm.error');
        }
    }
    /**
     * Deactivate
     */
    async deactivate() {
        const { data } = await this.graphql(`mutation { userDeactivate }`);
        if (data === null || data === void 0 ? void 0 : data.userDeactivate) {
            this.signOut();
        }
    }
}
export const userStore = new UserStore();
