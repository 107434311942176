import Store from '../../../lib/Store';
class CoinStore extends Store {
    constructor() {
        super({
            checkoutUrl: '',
            tally: 0,
            subscription: null
        });
    }
    /**
     * Checkout
     */
    async checkout(options) {
        const { data } = await this.graphql(`mutation { coinCheckout( mode: "${options.mode}" quantity: ${options.quantity} priceId: "${options.priceId}" redirect: "${options.redirect}") }`);
        if (data) {
            if (window.ga) {
                ;
                window.ga('send', 'event', { eventCategory: 'App Checkout', eventAction: 'coin Checkout', eventLabel: 'checkout' });
            }
            this.set({ checkoutUrl: data.coinCheckout }, 'checkout.success');
        }
    }
    /**
     * Grant
     */
    async grant(quantity) {
        const { data } = await this.graphql(`mutation { coinGrant(quantity: ${quantity}) { _id tally } }`);
        if (data)
            this.set({ tally: data.coinGrant.tally || 0 }, 'grant.success');
    }
    /**
     * Find Tally
     */
    async findTally() {
        const { data } = await this.graphql('query { coinTally }');
        if (data)
            this.set({ tally: data.coinTally || 0 }, 'findTally.success');
    }
    /**
     * Find Subscription
     */
    async findSubscription() {
        const { data } = await this.graphql(`query { coinSubscription {
        _id
        amount
        product
        units
        quantity
        subscriptionId
        startDate
        endDate
        canceled
        unlimited
        priceId
      }}`);
        if (data)
            this.set({ subscription: data.coinSubscription }, 'findSubscription.success');
    }
    /**
     * Cancel Subscription
     */
    async cancelSubscription() {
        const { data } = await this.graphql(`mutation { coinCancelSubscription }`);
        if (data.coinCancelSubscription)
            this.publish('updateSubscription.success');
    }
}
export const coinStore = new CoinStore();
