<script lang="ts">
  import { onMount } from 'svelte'
  import { alertStore } from '../../../common/alert/stores/AlertStore'
  import { userStore } from '../../users/stores/UserStore'
  import { clickThroughRateStore, IClickThroughRate, TDevice } from '../stores/ClickThroughRateStore'
  import { onSubscribe } from '../../../lib/helpers'


  const positions = []

  let form: Partial<IClickThroughRate> = {
    device: 'desktop',
    positions: positions
  }

  onSubscribe(clickThroughRateStore, updateByDeviceSuccess, 'updateByDevice.success')
  onSubscribe(clickThroughRateStore, findByDeviceSuccess, 'findByDevice.success')

  onMount(() => {
    userStore.validateAuth()
    clickThroughRateStore.findByDevice('desktop')
    clickThroughRateStore.findByDevice('mobile')
  })

  function updateByDeviceSuccess() {
    alertStore.add({ message: `Click through rates updated for ${form.device}` })
  }

  function findByDeviceSuccess(store) {
    updateForm('desktop')
  }

  function updateForm(device: TDevice) {
    const clickThroughRate = clickThroughRateStore.get().clickThroughRates.find(ctr => ctr.device == device)
    form.positions = clickThroughRate.positions
    form.device = device
  }

  function handleChange(e: Event, index) {
    form.positions[index] = Number((e.currentTarget as EventTarget & HTMLInputElement).value)
  }

  function handleSubmit() {
    clickThroughRateStore.updateByDevice(form)
  }

  function handleDeviceClick(device: TDevice) {
    updateForm(device)
  }

</script>

<main>
  <h1>Click Through Rates</h1>
  <ul class="nav nav-tabs mb-4">
    <li class="nav-item">
      <a class={form.device == 'desktop' ? 'nav-link active' : 'nav-link'} on:click|preventDefault={() => handleDeviceClick('desktop')} href="/">Desktop</a>
    </li>
    <li class="nav-item">
      <a class={form.device == 'mobile' ? 'nav-link active' : 'nav-link'} on:click|preventDefault={() => handleDeviceClick('mobile')} href="/">Mobile</a>
    </li>
  </ul>
  
  <div class="row">
    <div class="col mt-4 mb-3">

      <h4>{form.device}</h4>
      <form class="mt-3" on:submit|preventDefault={handleSubmit}>
        <div class="row">

          <div class="col-lg-6 mb-3">
            {#each form.positions.slice(0,5) as pos, index}
                <label class="form-label" for="position">Position {index + 1}</label>
                <div class="input-group">
                  <input type="number" class="form-control" step="0.01" value={pos} on:change={(e) => handleChange(e, index)} />
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              
            {/each}
          </div>
          <div class="col-lg-6 mb-3">
            {#each form.positions.slice(5, 10) as pos, index}
            
              <label class="form-label" for="position">Position {index + 6}</label>
              <div class="input-group">
                <input type="number" class="form-control" step="0.01" value={pos} on:change={(e) => handleChange(e, index)} />
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            
            {/each}
          </div>
        </div>

        <div class="row">
          <div class="col mt-4">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>

