import Store from '../../../lib/Store';
import { alertStore } from '../../alert/stores/AlertStore';
class TableStore extends Store {
    constructor() {
        super({ tables: undefined });
    }
    setStoreTable(s, id, rows, total = 0) {
        if (!s.tables)
            s.tables = { [id]: { rows, total } };
        else
            s.tables[id] = { rows, total };
        return s;
    }
    setData(id, rows) {
        this.update(s => {
            return this.setStoreTable(s, id, rows, rows.length);
        }, `setData.success.${id}`);
    }
    getData(id) {
        const data = this.get().tables;
        if (!data)
            return { rows: [], total: 0 };
        return this.get().tables[id];
    }
    async loadData({ id, query, variables }) {
        var _a;
        // Get data from server
        if (query) {
            const results = await this.graphql(query, variables);
            if ((_a = results.errors) === null || _a === void 0 ? void 0 : _a.length)
                alertStore.add(results.errors);
            this.update(s => {
                const [rows, total] = Object.values(results.data);
                return this.setStoreTable(s, id, rows || [], total !== undefined ? total : s.tables[id].total);
            }, `getData.success.${id}`);
        }
        else {
            this.update(s => s, `getData.success.${id}`);
        }
    }
    refresh(id) {
        this.update(s => s, `refresh.success.${id}`);
    }
}
export const tableStore = new TableStore();
