import Store from '../../../lib/Store';
const devices = ['desktop', 'mobile'];
class ClickThroughRateStore extends Store {
    constructor() {
        super({
            clickThroughRates: [...devices.map(device => ({
                    _id: '',
                    userId: '',
                    positions: [],
                    device,
                    createdAt: 0,
                    updatedAt: 0,
                    deletedAt: 0
                }))]
        });
    }
    /**
     * Update By Device
     */
    async updateByDevice({ device, positions }) {
        const { data } = await this.graphql(`mutation clickThroughRateUpdateByDevice($device: Device!, $positions: [Float!]!)
      { clickThroughRateUpdateByDevice(clickThroughRate: { device: $device, positions: $positions }) { 
        _id
        userId
        device
        positions
        createdAt
        updatedAt
        deletedAt
       }}
    `, {
            device, positions
        });
        if (data)
            this.update(s => {
                s.clickThroughRates.forEach(ctr => {
                    if (ctr.device == device)
                        Object.assign(ctr.device, data.clickThroughRateStoreUpdateByDevice);
                });
                return s;
            }, 'updateByDevice.success');
    }
    /**
     * Find By Device
     */
    async findByDevice(device) {
        const { data } = await this.graphql(`query { clickThroughRateByDevice(device: "${device}") { 
      _id
      userId
      device
      positions
      createdAt
      updatedAt
      deletedAt
    }}
    `);
        if (data)
            this.update(s => {
                s.clickThroughRates.forEach(ctr => {
                    if (ctr.device == device)
                        Object.assign(ctr, data.clickThroughRateByDevice);
                });
                return s;
            }, 'findByDevice.success');
    }
}
export const clickThroughRateStore = new ClickThroughRateStore();
