import Store from '../../../lib/Store';
class EmailStore extends Store {
    constructor() {
        super({
            templates: [],
            acceptedRecipients: 0
        });
    }
    /**
     * Find Templates
     */
    async findTemplates() {
        const { data } = await this.graphql('query { emailTemplates }');
        if (data)
            this.set({ templates: data.emailTemplates }, 'findTemplates.success');
    }
    /**
     * Send Templates
     */
    async sendTemplate(name, test = false) {
        const { data } = await this.graphql(`query { emailSendTemplate(name: "${name}", test: ${test}) }`);
        if (data)
            this.set({ acceptedRecipients: data.emailSendTemplate }, 'sendTemplate.success');
    }
}
export const emailStore = new EmailStore();
