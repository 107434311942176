
<script lang="ts">
  import Modal from '../../common/modal/components/Modal.svelte'
</script>

<Modal id="Support" title="SEO Arcade Support" >

  Have a question, or an issue? <br>
  The SEO Arcade team is here to help.<br><br>
  For details on application usage visit <a href="https://seoarcade.com/request-your-seo-keyword-forecast/" target="_blank">SEO Keyword Forecast</a><br>
  For application or billing issues contact our team at <a href="mailto:support@seoarcade.com">support@seoarcade.com</a>. <br>
  
</Modal>