<script lang="ts">
  import page from 'page'
  import SeoRoiForecastPage from './routes/seoRoiForecasts/pages/SeoRoiForecastPage.svelte'
  import usersRoutes from './routes/users/routes'
  import coinsRoutes from './routes/coins/routes'
  import brandRoutes from './routes/brand/routes'
  import clickThroughRatesRoutes from './routes/clickThroughRates/routes'
  import adminRoutes from './routes/admin/routes'
  import Topnav from './layouts/components/Topnav.svelte'
  import Footer from './layouts/components/Footer.svelte'
  import NotfoundPage from './layouts/pages/NotfoundPage.svelte'

  let component
  let ctx
  const routes = [
    { path: '/', component: SeoRoiForecastPage },
    ...usersRoutes,
    ...coinsRoutes,
    ...clickThroughRatesRoutes,
    ...adminRoutes,
    ...brandRoutes,
    { path: '/*', component: NotfoundPage },
  ]
  routes.forEach(r => page(r.path, (c) => {
    ctx = c
    component = r.component

    if(window.location.href.includes('app.seoarcade.com') && (window as any).ga) {
      ;(window as any).ga('set', 'page', r.path);
      ;(window as any).ga('send', 'pageview');
    }

  }))

  page.start()

</script>

<Topnav />
<div class="main container pt-4">
  <svelte:component this={component} ctx={ctx} />
</div>
<Footer />

<style>
  .main {
    min-height: calc(100vh - 141px);
    display: flex;
    flex-direction: column;
  }
</style>