<script lang="ts">
  import page from 'page'
  import { userStore } from '../stores/UserStore';
  let confirm = false

  /**
  * Handle Submit
  */
  function handleSubmit() {
    if(confirm) {
      // Deactivate account
      userStore.deactivate()
      confirm = false
    } else confirm = true

  }

  function handleCancel() {
    page('/users/account')
  }

</script>

<main>
  <h1>Sorry to see you go!</h1>

    <div class="intro row row-cols-auto mt-4 mb-4">
      <div class="col-xl-9">
        <p>
          Deactivating your account will stop any/all subscriptions and further access to your account!<br> 
          Before deactivation, ensure you have made copies of any generated reports, otherwise access to the original reports will be lost.
        </p>
      </div>
    </div>
    <p>
      <small>
        Having troubles and need help? We would love to make things right!<br>
        Please contact us at <a href="mailto:support@seoarcade.com">support@seoarcade.com</a><br>
      </small>
    </p>

    <form on:submit|preventDefault={handleSubmit}>
      {#if confirm}<button type="button" class="btn btn-secondary" on:click={handleCancel}>Cancel</button>{/if}
      <button type="submit" class={confirm ? 'btn btn-success' : 'btn btn-primary'} >{#if confirm}Confirm?{:else}Deactivate{/if}</button>
    </form>
</main>

<style>
  .intro {
    padding-top: 10px;
    color: #000;
    background-color: #f1f1f1;
    margin-left: 0px;
    margin-right: 0px;
  }
</style>  

