<script lang="ts">
  import { userStore } from  '../../users/stores/UserStore'
  import { onSubscribe } from '../../../lib/helpers'
  import { emailStore } from '../stores/EmailStore'
  import { onMount } from 'svelte'
import { alertStore } from '../../../common/alert/stores/AlertStore';

  let templates: string[] = []

  let form = {
    name: ''
  }
  let confirm = false


  onMount(() => {
    userStore.validateAuth('admin')
    emailStore.findTemplates()
  })

  onSubscribe(emailStore, onFindTemplatesSuccess, 'findTemplates.success')
  onSubscribe(emailStore, onSendTemplateSuccess, 'sendTemplate.success')

  function onFindTemplatesSuccess() {
    templates = emailStore.get().templates
  }

  function onSendTemplateSuccess() {
    form.name = ''
    alertStore.add({ message: `${emailStore.get().acceptedRecipients} emails sent.` })
  }

  function handleSubmit() {
    if(confirm) {
      confirm = false
      emailStore.sendTemplate(form.name)
    } else confirm = true
  }

  function handleTest() {
    emailStore.sendTemplate(form.name, true)
  }

</script>

<main>
  <h1>Emails</h1>
  <ul class="nav nav-pills mb-4">
    <li class="nav-item">
      <a class={location.pathname == '/admin' ? 'nav-link active' : 'nav-link'} aria-current="page" href="/admin">Admin</a>
    </li>
    <li class="nav-item">
      <a class={location.pathname == '/admin/emails' ? 'nav-link active' : 'nav-link'} aria-current="page" href="/admin/emails">Emails</a>
    </li>
  </ul>

  <form  on:submit|preventDefault={handleSubmit}>
    <div class="row ">

      <div class="col-lg mt-4">
        <legend>Templates</legend>
        <div class="col-lg-6 mb-1 ">
          <label class="form-label" for="name">Name</label>
          <select class="form-select form-select" bind:value={form.name}>
            {#each templates as template, i }
              {#if !i}<option value="" disabled selected hidden>Choose a template</option>{/if}
              <option value={template}>{template}</option>
            {/each}
          </select>
        </div>
      </div>
        
    </div>
    <div class="row">
      <div class="col-sm mt-4">
        {#if confirm}<button type="button" class="btn btn-secondary" on:click={() => confirm = false}>Cancel</button>{/if}
        <button type="submit" class={confirm ? 'btn btn-success' : 'btn btn-primary'} >{#if confirm}Confirm?{:else}Send{/if}</button>
        <button type="button" class="btn btn-secondary" on:click={handleTest}>Test</button>
      </div>
    </div>
  </form>

</main>
