<script lang="ts">
  import { onMount } from 'svelte'
  import page from 'page'
  import { userStore } from '../stores/UserStore'
  import { onSubscribe } from '../../../lib/helpers'


  const form = {
    email: '',
    password: ''
  }

  onSubscribe(userStore, onSignInSuccess, 'signIn.success')

  onMount(() => {
    if (userStore.get().self._id) page('/')
  })

  function onSignInSuccess() {
    page('/')
  }

  function handleSubmit(){
    userStore.signIn(form)
  }

</script>

<main>
  <h1>Sign In</h1>
  <form class="mt-4" on:submit|preventDefault={handleSubmit}>
    <div class="row">

      <div class="col-lg">
        <div class="col mt-3">
          <label class="form-label" for="email">Email Address</label>
          <input type="text" class="form-control" bind:value={form.email} />
        </div>

      </div>

      <div class="col-lg">
        <div class="col mt-3">
          <label class="form-label" for="password">Password</label>
          <input type="password" class="form-control" bind:value={form.password} />
        </div>
      </div>

    </div>

    <div class="row row-cols-auto mt-4">
      <div class="col">
        <button type="submit" class="btn btn-primary">Sign In</button>
      </div>
      <div class="col mt-1">
        <a href="/users/forgotPassword"> Forgot Password?</a>
      </div>
      <div class="col mt-1">
        <a href="/users/signup">Need an Account? Sign Up</a>
      </div>
    </div>
  </form>
  

</main>