<script lang="ts">
  import { onMount } from 'svelte'
  import { userStore } from '../stores/UserStore'
  import { onSubscribe } from '../../../lib/helpers'

  let email = ''

  onMount(() => {
    userStore.unsubscribe()
  })

  onSubscribe(userStore, onUnsubscribeSuccess, 'unsubscribe.success')

  function onUnsubscribeSuccess() {
    email = userStore.get().self.email
  }

</script>

<main>
  <h1>Unsubscribed</h1>
  <div class="row mt-4">
    <div class="col-lg">
      <p class="h4">{email} is now unsubscribed.</p>
      <p class="mt-4">Sorry to see you go. We would love to know how we can do better at support@seoarcade.com.</p>
      <p><mark>You can reactivate promotional emails any time in your <a href="/users/account">Account</a> page.</mark></p>
    </div>
  </div>

</main>

