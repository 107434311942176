
<script lang="ts">
  import { onMount } from 'svelte'
  import Config from '../../../lib/Config'
  import { userStore } from '../../users/stores/UserStore'
  import { coinStore, ICoin } from '../stores/CoinStore'
  import Modal from '../../../common/modal/components/Modal.svelte'
  import { onSubscribe } from '../../../lib/helpers'

  export let message: string = ''
  export let redirect: string = '/coins'
  export let priceId: string = Config.get('stripe.payment.1000.1000') + '.3'

  const pricingSeoRoiForecast = Config.get('pricing.seoRoiForecast')
  const form = {
    priceId: Config.get('stripe.payment.1000.1000') + '.3'
	}

  let hasAdmin = false
  let paymentType = 'subscribe'
  let subscriptionType = 'coin'
  let payments: { label: string, value: string }[] = []
  let subscriptionName: string = ''
  let subscriptionEnded: boolean
  let subscription: Partial<ICoin>
  
  onMount(() => {
    hasAdmin = userStore.hasAuth('admin')
    priceId = ""
    loadPayments()
    coinStore.findSubscription()
  })

  onSubscribe(coinStore, onCoinFindSubscriptionSuccess, 'findSubscription.success')

  $: form.priceId = priceId

  function onCoinFindSubscriptionSuccess() {
    subscription = coinStore.get().subscription
    if(subscription) {
      priceId = subscription.priceId

      subscriptionName = 'Unlimited coins per month'
      subscriptionType = 'unlimited'

      subscriptionEnded = subscription.canceled && Date.parse(subscription.endDate) < Date.now()
    }
  }

  function loadPayments() {
    if(hasAdmin)
      payments.push({
        label: `$1 for 100 coins`,
        value: Config.get(`stripe.payment.100.100`) + '.1'
      })

    for(let count = 1000; count <= 10000; count = count + 1000) {
      payments.push({
        label: `$${count/100} for ${count} coins`,
        // Need the count at the end so value are different for the select
        value: Config.get(`stripe.payment.1000.1000`) + `.${count/1000}`
      })
    }
  }

  function handlePaymentSubmit() {
    const [priceId, quantity] = form.priceId.split('.')
    coinStore.checkout({ mode: 'payment', priceId, quantity: Number(quantity), redirect })
  }

  function handleSubscribeSubmit() {
    coinStore.checkout({ mode: 'subscription', quantity: 1, redirect })
  }

  function handlePaymentClick() {
    paymentType = 'payment'
    priceId = Config.get('stripe.payment.1000.1000') + '.3'
  }

  function handleSubscriptionClick() {
    paymentType = 'subscribe'
    priceId = ""
  }

  function cancelSubscription() {
    coinStore.cancelSubscription()
  }

</script>

<Modal id="CoinCheckout" title="Subscribe" >

  {#if message}
    <div class="row">
      <div class="col intro">{message}</div>
    </div>
    <br>
  {/if}

  <ul class="nav nav-tabs mb-4">
    <li class="nav-item">
      <a class={paymentType == 'subscribe' ? 'nav-link active' : 'nav-link'} on:click|preventDefault={handleSubscriptionClick} href="/">Unlimited Reports</a>
    </li>
    <li class="nav-item">
      <a class={paymentType == 'payment' ? 'nav-link active' : 'nav-link'} on:click|preventDefault={handlePaymentClick} href="/">Coins On Demand</a>
    </li>
  </ul>

  {#if paymentType == 'subscribe'}

    <form on:submit|preventDefault={handleSubscribeSubmit}>
      <div class="row row-cols-auto mt-2">
        <div class="col col-lg-12">
          <div class="form-group mt-2">
            {#if subscriptionName}
              {#if subscriptionEnded}
                <p>Expired Subscription</p>
              {:else}
                <p>Current Subscription</p>
              {/if}
              <p class="current-subscription">{subscriptionName}
                {#if subscription.canceled}
                  <br><i><small>End{#if subscriptionEnded}ed{:else}ing{/if} on {(new Date(subscription.endDate)).toLocaleDateString()}</small></i>
                {/if}
              </p>
              {:else}
              <div class="row">
                <p>
                  <i>Get all the SEO forecasts you need for your agency, consultancy or business with our unlimited monthly subscription for $20 per month.</i>
                </p>
              </div>
            {/if}
          </div>
        </div>
        
      </div>
      <div class="row row-cols-auto mt-4">
        <div class="col col-lg-12">
          {#if subscriptionName && !subscription.canceled}
            <button class="btn btn-outline-secondary" on:click|preventDefault={cancelSubscription}>Cancel Subscription</button>
          {:else}
            <button type="submit" class="btn btn-primary">Subscribe</button>
          {/if}
        </div>
      </div>

    </form>

  {:else}

    <form on:submit|preventDefault={handlePaymentSubmit}>
      <div class="row row-cols-auto mt-2">
        <div class="col col-lg-12">
          <div class="form-group mt-2">
            <div class="row mb-4">
              <i>If you’re not ready to sign up for the unlimited reports you can get SEO forecasts on an ad-hoc, coin based system. {pricingSeoRoiForecast} coins per report.<br><br>
                If you buy coins individually, it’s $10 for 1,000.<br>
              </i>
            </div>
            <label class="form-label" for="quantity">Quantity</label>

            <select class="form-control form-select" bind:value={form.priceId}>
              {#each payments as pay}
                <option value={pay.value}>{pay.label}</option>
              {/each}
            </select>
          </div>
        </div>
        
      </div>
      <div class="row row-cols-auto mt-4">
        <div class="col col-lg-12">
          <button type="submit" class="btn btn-primary">Get coins</button>
        </div>
      </div>

    </form>

  {/if}

  <div class="mt-4">
    <hr/>
  </div>

  <div class="float-start">
    SEO ROI Forecast = {pricingSeoRoiForecast} coins
  </div>
  <div class="float-end">
    <a href="https://seoarcade.com/keyword-research-pricing/#terms-and-conditions" target="_blank">Coin Terms</a>
  </div>
  
</Modal>

<style>
  .intro {
    margin: 0 12px 0 12px;
    padding: 12px;
    color: #000;
    background-color: #f1f1f1;
  }
  .current-subscription {
    font-size: 1.5rem;
    padding: 5px;
    /* border: 2px solid rgb(145 154 161); */
    margin-bottom: 30px;
  }

</style>