<script lang="ts">
  import { onMount } from 'svelte'
  import { userStore } from '../../users/stores/UserStore'
  import { brandStore } from '../stores/BrandStore'
  import type { IBrand } from '../stores/BrandStore'
  import { onSubscribe } from '../../../lib/helpers'
  import { alertStore } from '../../../common/alert/stores/AlertStore'


  onMount(() => {
    userStore.validateAuth('user')
    brandStore.findDefault()
  })

  let fileinput

  let form: Partial<IBrand> = {
    name: '',
    url: '',
    logo: '',
    logoHeight: 0,
    logoWidth: 0
  }

  onSubscribe(brandStore, onFindDefaultSuccess, 'findDefault.success')
  onSubscribe(brandStore, onUpdateDefaultSuccess, 'updateDefault.success')

  function onFindDefaultSuccess() {
    const brand = brandStore.get().brand
    form.name = brand.name
    form.url = brand.url
    form.logo = brand.logo
    form.logoHeight = brand.logoHeight
    form.logoWidth = brand.logoWidth
  }

  function onUpdateDefaultSuccess() {
    alertStore.add({ message: 'Brand updated.' })
  }
	
	function handleFileSelected(e) {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      const image = new Image()
      image.src = e.target.result as string
      image.onload = function() {
        if(image.height > 200 || image.width > 200) {
          alertStore.add({ message: 'Image size max width and height are 200px.'})
          return
        }
        form.logo = e.target.result as string
        form.logoHeight = image.height
        form.logoWidth = image.width
      }
    }
  }

  function handleDeleteLogo() {
    form.logo = ''
    form.logoHeight = 0
    form.logoWidth = 0
  }

  function handleSubmit() {
    brandStore.updateDefault(form)
  }
  
</script>
  
<main>
  <h1>Brand</h1>
  <form class="mt-4" on:submit|preventDefault={handleSubmit}>
    <div class="row">

      <div class="col-lg">
        <legend>General</legend>
        <div class="col-lg-6 mb-3 ">
          <label class="form-label" for="name">Brand Name</label>
          <input type="text" class="form-control" bind:value={form.name}/>
        </div>

        <div class="col-lg-6 mb-1 ">
          <label class="form-label" for="name">Brand Url</label>
          <input type="text" class="form-control" bind:value={form.url}/>
        </div>
      </div>

      <div class="col-lg">
        <legend class="mt-2">Logo</legend>

        <div class="row">
          <div class="col-lg-4">
            <div class="logo-wrapper">
              {#if form.logo}
                <img class="logo" src="{form.logo}" alt="d" />
              {:else}
                <div class="empty-logo">Upload Image</div>
              {/if}
            </div>

            <div class="row">
              <small  class="mt-2">Max 200px x 200px.</small >
            </div>

          </div>
          <div class="col-lg-2 mt-4">
            <div class="container">
              <div class="row">
                <button class="mt-1 btn btn-sm btn-outline-primary" on:click|preventDefault={ () => { fileinput.click() }}>Set</button>
              </div>
              <div class="row">
                <button class="mt-1 btn btn-sm btn-outline-primary" on:click|preventDefault={handleDeleteLogo}>Del</button>
              </div>
            </div>
            <input style="display:none" type="file" accept=".jpg, .jpeg, .png" on:change={ handleFileSelected } bind:this={fileinput} />
          </div>
        </div>
        
      </div>
    </div>
    <div class="row">
      <div class="col-sm mt-4">
        <button type="submit" class="btn btn-primary">Save</button>
      </div>
    </div>
  </form>

</main>

<style>

  .logo-wrapper {
    height: 200px;
    width: 200px;
  }

  .logo {
    max-height: 200px;
    max-width: 200px;
  }

  .empty-logo {
    padding-top: 70px;
    height: 200px;
    width: 200px;
    color: #ccc;
    font-size: .8em;
  }

</style>