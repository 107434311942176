import Store from '../../../lib/Store';
class BrandStore extends Store {
    constructor() {
        super({
            brand: {
                _id: '',
                logo: '',
                logoHeight: 0,
                logoWidth: 0,
                name: '',
                url: '',
                createdAt: 0,
                updatedAt: 0,
                deletedAt: 0
            }
        });
    }
    /**
     * Update Default
     */
    async updateDefault({ name, url, logo }) {
        const { data } = await this.graphql(`mutation brandUpdateDefault($name: String, $url: String, $logo: String, $logoHeight: Int, $logoWidth: Int)
      { brandUpdateDefault(brand: { name: $name, url: $url, logo: $logo, logoHeight: $logoHeight, logoWidth: $logoWidth }) { 
        _id
        name
        url
        logo
        logoHeight
        logoWidth
        createdAt
        updatedAt
        deletedAt
       }}
    `, {
            name, url, logo
        });
        if (data)
            this.set({ brand: data.brandUpdateDefault }, 'updateDefault.success');
    }
    /**
     * Find Default
     */
    async findDefault() {
        const { data } = await this.graphql(`query { brandDefault { 
      _id
      name
      url
      logo
      logoHeight
      logoWidth
      createdAt
      updatedAt
      deletedAt
    }}
    `);
        if (data)
            this.set({ brand: data.brandDefault }, 'findDefault.success');
    }
}
export const brandStore = new BrandStore();
