import Store from '../../../lib/Store';
class SeoRoiForecastStore extends Store {
    constructor() {
        super({
            rerunTally: {
                keyword: '',
                rerunTally: 0
            }
        });
    }
    getLocationNames() {
        return [
            'Algeria',
            'Angola',
            'Azerbaijan',
            'Argentina',
            'Australia',
            'Austria',
            'Bahrain',
            'Bangladesh',
            'Armenia',
            'Belgium',
            'Bolivia',
            'Brazil',
            'Bulgaria',
            'Myanmar (Burma)',
            'Belarus',
            'Cambodia',
            'Cameroon',
            'Canada',
            'Sri Lanka',
            'Chile',
            'Taiwan',
            'Colombia',
            'Costa Rica',
            'Croatia',
            'Cyprus',
            'Czechia',
            'Denmark',
            'Ecuador',
            'El Salvador',
            'Estonia',
            'Finland',
            'France',
            'Germany',
            'Ghana',
            'Greece',
            'Guatemala',
            'Hong Kong',
            'Hungary',
            'India',
            'Indonesia',
            'Ireland',
            'Israel',
            'Italy',
            'Cote d\'Ivoire',
            'Japan',
            'Kazakhstan',
            'Jordan',
            'Kenya',
            'South Korea',
            'Latvia',
            'Lithuania',
            'Malaysia',
            'Malta',
            'Mexico',
            'Morocco',
            'Netherlands',
            'New Zealand',
            'Nicaragua',
            'Nigeria',
            'Norway',
            'Pakistan',
            'Paraguay',
            'Peru',
            'Philippines',
            'Poland',
            'Portugal',
            'Romania',
            'Russia',
            'Saudi Arabia',
            'Senegal',
            'Serbia',
            'Singapore',
            'Slovakia',
            'Vietnam',
            'Slovenia',
            'South Africa',
            'Spain',
            'Sweden',
            'Switzerland',
            'Thailand',
            'United Arab Emirates',
            'Tunisia',
            'Turkey',
            'Ukraine',
            'North Macedonia',
            'Egypt',
            'United Kingdom',
            'United States',
            'Burkina Faso',
            'Uruguay',
            'Venezuela'
        ];
    }
    getLanguageNames() {
        return ['Arabic',
            'Armenian',
            'Azeri',
            'Bengali',
            'Bulgarian',
            'Chinese (Simplified)',
            'Chinese (Traditional)',
            'Croatian',
            'Czech',
            'Danish',
            'Dutch',
            'English',
            'Estonian',
            'Finnish',
            'French',
            'German',
            'Greek',
            'Hebrew',
            'Hindi',
            'Hungarian',
            'Indonesian',
            'Italian',
            'Japanese',
            'Korean',
            'Latvian',
            'Lithuanian',
            'Macedonian',
            'Malay',
            'Norwegian (BokmÃ¥l)',
            'Polish',
            'Portuguese',
            'Romanian',
            'Russian',
            'Serbian',
            'Slovak',
            'Slovenian',
            'Spanish',
            'Swedish',
            'Tagalog',
            'Thai',
            'Turkish',
            'Ukrainian',
            'Urdu',
            'Vietnamese'];
    }
    /**
     * Generate
     */
    async generate({ keyword, domain, seed, conversionRate, saleRate, revenuePerSale, device, locationName, languageName }) {
        const { errors } = await this.graphql(`mutation seoRoiForecastGenerate($keyword: Keyword!, $domain: Domain!, $seed: Boolean!, $conversionRate: Float!, $saleRate: Float!, $revenuePerSale: Float!, $device: NonEmptyString!, $locationName: NonEmptyString!, $languageName: NonEmptyString!)
      { seoRoiForecastGenerate(seoRoiForecast: { keyword: $keyword, domain: $domain, seed: $seed, conversionRate: $conversionRate, saleRate: $saleRate, revenuePerSale: $revenuePerSale, device: $device, locationName: $locationName, languageName: $languageName }) }
    `, {
            keyword,
            domain,
            conversionRate: parseFloat(String(conversionRate)),
            seed,
            saleRate: parseFloat(String(saleRate)),
            revenuePerSale: parseFloat(String(revenuePerSale)),
            device,
            locationName,
            languageName
        });
        if (errors)
            this.publish('generate.error');
        else
            this.publish('generate.success');
    }
    async findRerunTally(keyword) {
        const { data } = await this.graphql(`query { seoRoiForecastRerunTally(keyword: "${keyword}") { rerunTally keyword } }`);
        if (data)
            this.set({ rerunTally: data.seoRoiForecastRerunTally }, 'findRerunTally.success');
    }
}
export const seoRoiForecastStore = new SeoRoiForecastStore();
