<script lang='ts'>
  import type { IColumn } from './types'

  interface IParam {
    id: string
    onClick?: (row: Record<string, any>, cell: IColumn, link: IParam) => void
    label: string
    to?: string
  }

  export let row: Record<string, any>
  export let cell: IColumn
  export let componentParams: {links: IParam[]}
</script>

<td class={cell.class || ''} id={cell.id} width={cell.width || ''}>
  <div class="thead">{cell.title}</div>
  {#each componentParams.links as link, index}
    {#if link.onClick}
      <button type="button" class="btn btn-outline-primary btn-sm" on:click={() => link.onClick(row, cell, link)}>{link.label || row[link.id]}</button>
    {:else if link.to || row[link.id]}
      <button type="button" class="btn btn-outline-primary btn-sm" on:click={() => window.open(link.to || row[link.id])} >{link.label || row[link.id]}</button>
    {/if}
  {/each}
  
</td>

<style>
  td {
    padding: 1rem .625rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.5s;
    border-top: 1px solid #ddd;
  }
  .btn {
    margin-left: 5px;
    display: inline;
  }

  @media screen and (min-width: 39.9375em) {
    .thead {
      display: none;
    }
  }

  @media screen and (max-width: 39.9375em) {

    .thead {
      text-align: center;
    }
    td {
      display: block;
      width: 400px;
      text-align: center;
    }
  }
</style>