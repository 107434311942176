<script lang="ts" >
  import type { IDropdownLink } from './types'
  import { uuid } from '../../../lib/helpers'

  export let links: IDropdownLink[]
  export let trigger
  export let buttonClass: string = ''

  const id = uuid()
  let show = false

  function handleClick() {
    show = !show
  }

  function handleLinkClick(link: IDropdownLink) {
    show = false
    link.onClick(link)
  }

</script>

<div id={id} class="dropdown">
  <button class="btn btn-secondary dropdown-toggle {buttonClass}" type="button" on:click={handleClick}>
    {trigger.label}
  </button>
  {#if show}
    <ul class="dropdown-menu dropdown-menu-end" on:click={handleClick}>
      {#each links as link}
        {#if link.text}
          <li>
            <span class="dropdown-item" >{link.text}</span>
          </li>
        {:else if link.divider}
          <li>
            <hr class="dropdown-divider" />
          </li>
        {:else if link.onClick}
          <li>
            <button type="button" class="dropdown-item" on:click={() => handleLinkClick(link)}>{link.label}</button>
          </li>
        {:else if link.to}
          <li>
            <a class="dropdown-item" href={link.to}>{link.label}</a>
          </li>
        {/if}
      {/each}
    </ul>
  {/if}
</div>

<style>
  .dropdown-menu {
    display: block;
    border: 2px solid rgb(145 154 161);
  }

  .dropdown-item {
    padding: .5rem 1rem;
  }

</style>