<script lang="ts">
  import page from 'page'
  import { userStore, IUser } from  '../../users/stores/UserStore'
  import { onSubscribe } from '../../../lib/helpers';
  import { onMount } from 'svelte'
  import Table from '../../../common/table/components/Table.svelte'
  import TableLinks from '../../../common/table/components/TableLinks.svelte'

  
  onSubscribe(userStore, onProxySuccess, 'proxy.success')

  onMount(() => {
    userStore.validateAuth('admin')
    // Dont let proxies proxy others, lol
    if(userStore.hasAuth('proxy')) {
      page('/')
    }
  })

  function handleClick(row: IUser) {
    userStore.proxy(row._id)
  }

  function onProxySuccess() {
    // We reload the window so all objects are clear
    location.reload()
    page('/')
  }

</script>

<main>

  <h1>Admin</h1>
  <ul class="nav nav-pills mb-4">
    <li class="nav-item">
      <a class={location.pathname == '/admin' ? 'nav-link active' : 'nav-link'} aria-current="page" href="/admin">Admin</a>
    </li>
    <li class="nav-item">
      <a class={location.pathname == '/admin/emails' ? 'nav-link active' : 'nav-link'} aria-current="page" href="/admin/emails">Emails</a>
    </li>
  </ul>

  <div class="row">
    <div class="col mt-4">
    <Table
      id="users"
      title="Users"
      columns={[
      { id: 'email', width: '200', title: 'Email', sortable: true },
      { id: 'firstName', width: '200',title: 'First Name', sortable: true},
      { id: 'lastName', width: '200',title: 'Last Name', sortable: true },
      { id: 'auth', width: '200',title: 'Auth', sortable: true },
      { id: 'active', width: '200',title: 'Active', sortable: true },
      { id: 'seoRoiForecasts', width: '200', title: 'Reports', sortable: true },
      { id: 'createdAt', width: '200', format: 'date', title: 'Created At', sortable: true },
      { id: 'updatedAt', width: '200', format: 'date', title: 'Updated At', sortable: true },
      { id: 'actions', width: '200', title: '', component: TableLinks, componentParams: { links: [
            { id: 'rerun', onClick: handleClick, label: 'Proxy' }
      ]}},
      ]}
      query={`
      query users($collation: Collation!){ 
        users(collation: $collation ) {
        _id
        email
        firstName
        lastName
        auth
        active
        seoRoiForecasts
        createdAt
        updatedAt
        }
        <<total usersTotal(collation: $collation) total>> 
      }`
      }
    />
    </div>
  </div>
</main>
