import SigninPage from './pages/SigninPage.svelte';
import SignupPage from './pages/SignupPage.svelte';
import AccountPage from './pages/AccountPage.svelte';
import ForgotPasswordPage from './pages/ForgotPasswordPage.svelte';
import ResetPasswordPage from './pages/ResetPasswordPage.svelte';
import UnsubscribePage from './pages/UnsubscribePage.svelte';
import ConfirmPage from './pages/ConfirmPage.svelte';
import DeactivatePage from './pages/DeactivatePage.svelte';
export default [
    { path: '/users/account', component: AccountPage },
    { path: '/users/account', component: AccountPage },
    { path: '/users/signin', component: SigninPage },
    { path: '/users/signup', component: SignupPage },
    { path: '/users/forgotPassword', component: ForgotPasswordPage },
    { path: '/users/resetPassword', component: ResetPasswordPage },
    { path: '/users/unsubscribe', component: UnsubscribePage },
    { path: '/users/confirm', component: ConfirmPage },
    { path: '/users/deactivate', component: DeactivatePage }
];
