<script lang="ts">
  import { onMount } from 'svelte'
  import { userStore } from '../stores/UserStore'
  import type { IUser } from '../stores/UserStore'
  import { onSubscribe } from '../../../lib/helpers'
  import Tooltip from '../../../common/tooltip/Tooltip.svelte'
  import { alertStore } from '../../../common/alert/stores/AlertStore';
  

  let form: Partial<IUser> = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    promotionalEmail: true
  }

  onSubscribe(userStore, onUpdateSelfSuccess, 'selfUpdate.success')
  onSubscribe(userStore, onSelfSuccess, 'self.success')

  onMount(() => {
    userStore.validateAuth()
    userStore.self()
  })

  function onUpdateSelfSuccess() {
    alertStore.add({ message: 'Account updated.' })
  }

  function onSelfSuccess() {
    form = userStore.get().self
  }

  function handleSubmit() {
    userStore.selfUpdate(form)
  }

</script>

<main>
  <h1>Account</h1>
  <form class="mt-4" on:submit|preventDefault={handleSubmit}>
    <div class="row">

      <div class="col-lg">
        <legend>Name</legend>
        <div class="col mt-3">
          <label class="form-label" for="firstName">First Name</label>
          <input type="text" class="form-control" bind:value={form.firstName}/>
        </div>
        <div class="col mt-3">
          <label class="form-label" for="lastName">Last Name</label>
          <input type="text" class="form-control" bind:value={form.lastName}/>
        </div>
      </div>

      <div class="col-lg">
        <legend>Security</legend>
        <div class="col mt-3">
          <label class="form-label" for="email">Email Address</label>
          <input type="text" class="form-control" bind:value={form.email}/>
        </div>
        <div class="col mt-3">
          <label class="form-label" for="password">Password <Tooltip>Leave empty to keep current password.</Tooltip></label>
          <input type="password" class="form-control" bind:value={form.password}/>
        </div>
        
      </div>
    </div>

    <div class="row">

      <div class="col-lg mt-4">
        <legend>Email</legend>
        <div class="col-lg-6 mb-1 form-check">
          <input class="form-check-input" type="checkbox"  bind:checked={form.promotionalEmail}/>
          <label class="form-check-label" for="promotionalEmail">
            Promotional <Tooltip>Receive SEO Arcade news and discounts. We NEVER share your email.</Tooltip>
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm mt-4">
        <button type="submit" class="btn btn-primary">Save</button>
      </div>
      <div class="col-md-auto mt-4">
        <a class="deactivate" href="/users/deactivate">Account Deactivation</a>
      </div>
    </div>
  </form>
  

</main>
