import config from '../config.json';
class Config {
    constructor() {
        this.state = config.default;
        this.state.env = window.location.href.startsWith('https://app.seoarcade.com') ? 'production' : 'development';
        Object.assign(this.state, config[this.state.env]);
    }
    /**
     * Get
     */
    get(path, defaulter = '') {
        return this.state[path] || defaulter;
    }
}
export default new Config();
