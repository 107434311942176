class Events {
    subscribe(type, listener) {
        document.addEventListener(type, listener);
        return () => this.unsubscribe(type, listener);
    }
    publish(type, detail) {
        document.dispatchEvent(new CustomEvent(type, { detail }));
    }
    unsubscribe(type, listener) {
        document.removeEventListener(type, listener);
    }
}
export const events = new Events();
