<script lang="ts">
  import { userStore } from  '../../users/stores/UserStore'
  import { onDestroy, onMount } from 'svelte'
  import { formatCsv, onSubscribe } from '../../../lib/helpers'
  import { seoRoiForecastStore, ISeoRoiForecast } from '../stores/SeoRoiForecastStore'
  import { modalStore } from '../../../common/modal/stores/ModalStore'
  import { tableStore } from '../../../common/table/stores/TableStore'
  import SeoRoiForecastGenerate from '../modals/SeoRoiForecastGenerate.svelte'
  import SeoRoiForecastReport from '../modals/SeoRoiForecastReport.svelte'
  import Table from '../../../common/table/components/Table.svelte'
  import TableLinks from '../../../common/table/components/TableLinks.svelte'
  import CoinCheckout from '../../coins/modals/CoinCheckout.svelte'
  import { coinStore } from '../../coins/stores/CoinStore'
  import Config from '../../../lib/Config'
  import { alertStore } from '../../../common/alert/stores/AlertStore'
  import TableKeywords from '../table/TableKeywords.svelte'


  const formDefault = {
    keyword: '',
    domain: '',
    conversionRate: 0.00,
    seed: true,
    saleRate: 0.00,
    revenuePerSale: 0.00,
    device: 'desktop',
    locationName: 'United States',
    languageName: 'English'
  }

  let isRerun = false

  let shortCoins = 0
  let seoRoiForecastsIntervalId
  let form = {...formDefault}
  let reportLocation = ''
  let reportStatus = 'generating'
  let tally = 0

  onMount(() => {
    userStore.validateAuth()
    coinStore.findTally()
  })

  onDestroy(() => {
    window.clearInterval(seoRoiForecastsIntervalId)
  })

  onSubscribe(coinStore, onCoinCheckoutSuccess, 'checkout.success')
  onSubscribe(coinStore, onCoinFindTallySuccess, 'findTally.success')
  onSubscribe(seoRoiForecastStore, onGenerateSuccess, 'generate.success')
  onSubscribe(seoRoiForecastStore, onGenerateError, 'generate.error')
  onSubscribe(seoRoiForecastStore, onFindRerunTallySuccess, 'findRerunTally.success')

  function onGenerateSuccess() {
    let x = 0
    seoRoiForecastsIntervalId = setInterval(() => {
      if (++x === 6) {
        window.clearInterval(seoRoiForecastsIntervalId)
      }
      tableStore.refresh('seoRoiForecasts')
    }, 20000)
  }

  function onGenerateError() {
    window.clearInterval(seoRoiForecastsIntervalId)
  }

  function onCoinFindTallySuccess() {
    tally = coinStore.get().tally
  }

  function onFindRerunTallySuccess(): void {
    if(form.keyword !== seoRoiForecastStore.get().rerunTally.keyword) {
      alertStore.add({ message: 'Keyword does not match requested rerun.' })
      return
    }

    const rerunsLeft = Number(Config.get('reruns.seoRoiForecast')) - seoRoiForecastStore.get().rerunTally.rerunTally
    if(rerunsLeft <= 0) {
      alertStore.add( { message: `Insufficient reruns for '${seoRoiForecastStore.get().rerunTally.keyword}'. Please continue by purchasing a report.`})
      handleGenerateForecast()
    }
    else
      modalStore.show('SeoRoiForecastGenerate')
  }

  function onCoinCheckoutSuccess() {
    window.location.href = coinStore.get().checkoutUrl
  }

  function handleGenerateForecast() {
    form = {...formDefault}
    isRerun = false

    shortCoins = tally - Number(Config.get('pricing.seoRoiForecast'))

    if(shortCoins < 0) // 0 would mean we have exactly the right amount of coins
      modalStore.show('CoinCheckout')
    else
      modalStore.show('SeoRoiForecastGenerate')
  }

  function handleRerunClick(row: ISeoRoiForecast) {
    form = {
      keyword: formatCsv(row.keyword),
      domain: formatCsv(row.domain),
      conversionRate: row.conversionRate,
      seed: row.seed,
      saleRate: row.saleRate,
      revenuePerSale: row.revenuePerSale,
      device: row.device || formDefault.device,
      locationName: row.locationName || formDefault.locationName,
      languageName: row.languageName || formDefault.languageName,
    }
    isRerun = true
    seoRoiForecastStore.findRerunTally(row.keyword)
  }

  function handleView(row) {
    reportLocation = row.reportLocation
    reportStatus = row.status
    modalStore.show('SeoRoiForecastReport')
  }
  
</script>

<main>
  <SeoRoiForecastReport reportLocation={reportLocation} reportStatus={reportStatus} />
  <SeoRoiForecastGenerate form={form} isRerun={isRerun} />
  <CoinCheckout 
    message={`Looks like your pocket is short ${Math.abs(shortCoins)} coins. Get more coins to continue.`}
    redirect="/?openModal=SeoRoiForecastGenerate"
  />

  <h1>Keyword Research Simplified</h1>
  <div class="intro row row-cols-auto mt-4 mb-4">
    <div class="col-xl-9">
      <p>
        All we need is 1-5 "seed" keywords, and we'll look at the top 10 URLs for those SERPS, and extract the top 100 keywords from those pages. We generate our reports in Google Sheets, so you can edit, share and modify your data without pesky downloading CSV files etc.
      </p>
    
      <p>We also can project potential traffic, conversions, sales & revenue with a few quick inputs for you, so you can turn your keyword discovery into a full SEO market analysis and potential SEO forecast. Here's guides on using our data as an <a href="https://seoarcade.com/in-house-seo-use-case/" target="_blank">in-house SEO</a>, a white-label solution for <a href="https://seoarcade.com/bulk-reports-agency-partners/" target="_blank">SEO agencies</a>, or as an <a href="https://seoarcade.com/how-to-use-our-keyword-forecast-reports/" target="_blank">intent analysis tool</a>.</p>
      <p>Still have questions? <a href="https://calendly.com/seoarcade/15min?month=2022-03" target="_blank">Let's chat</a> and I'll walk you through the data, the tool and it's uses.</p>
    </div>

    <div class="col-xl-3 mt-2">
      <iframe width="300" height="150" src="https://www.youtube.com/embed/aAFZDU7iD9A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>

  <div class="mt-4 row">
    <div class="col">
      <button class="float-lg-end btn btn-primary" on:click={handleGenerateForecast}>Generate Forecast</button>
    </div>
  </div>

  <div class="row">
    <div class="col mt-4">
      <Table
        id="seoRoiForecasts"
        title="SEO ROI Forecasts"
        manualRefresh={true}
        sortBy="createdAt"
        sortDirection={-1}
        columns={[
          { id: 'createdAt', width: '130', title: 'Date', format: 'date', sortable: true},
          { id: 'keyword', width: '250', title: 'Keyword', sortable: true, component: TableKeywords },
          // { id: 'seed', width: '50', title: 'Seeded', sortable: true },
          { id: 'conversionRate', width: '100', title: 'Conversion Rate', format: 'percent', sortable: true },
          { id: 'saleRate', width: '100', title: 'Sale Rate', format: 'percent', sortable: true },
          { id: 'revenuePerSale', width: '100', title: 'Revenue Per Sale', format: 'currency', sortable: true },
          { id: 'device', width: '100', title: 'Device', sortable: true },
          { id: 'locationName', width: '100', title: 'Location', sortable: true },
          { id: 'languageName', width: '100', title: 'Language', sortable: true },
          { id: 'status', title: 'Status', sortable: true },
          { id: 'actions', width: '175', title: '', component: TableLinks, componentParams: { links: [
            { id: 'reportLocation', onClick: handleView, label: 'View'},
            { id: 'rerun', onClick: handleRerunClick, label: 'Rerun' }
          ]}}
        ]}
        query={`
          query seoRoiForecasts($collation: Collation!){ 
            seoRoiForecasts(collation: $collation ) {
              _id
              keyword
              domain
              seed
              conversionRate
              saleRate
              revenuePerSale
              device
              locationName
              languageName
              reportLocation
              status
              createdAt
            }
            <<total seoRoiForecastsTotal(collation: $collation) total>> 
          }`
        }
      />
    </div>
  </div>
</main>

<style>
  .intro {
    padding-top: 10px;
    color: #000;
    background-color: #f1f1f1;
    margin-left: 0px;
    margin-right: 0px;
  }
</style>