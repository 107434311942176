<script lang="ts">
  import { onMount } from 'svelte'
  import page from 'page'
  import { userStore } from '../stores/UserStore'
  import { onSubscribe } from '../../../lib/helpers'


	const form = {
		firstName: '',
		lastName: '',
		email: '',
		password: ''
	}

  onSubscribe(userStore, onSignUpSuccess, 'signUp.success')

  onMount(() => {
    if (userStore.get().self._id) page('/')
  })

  function onSignUpSuccess() {
    page('/users/confirm')
  }

	/**
	* Handle Sign Up
	*/
	function handleSubmit(){
		userStore.signUp(form)
	}

</script>

<main>
  <h1>Sign Up</h1>

  <div class="intro row row-cols-auto mt-4 mb-4">
    <div class="col-xl-9">
      <p>
        Keyword Research Simplified<br>
        Get your 5 free keyword research reports! We generate our reports in Google Sheets,<br> 
        so you can edit, share and modify your data without pesky downloading CSV files. Get Started!
      </p>
    </div>
  </div>

  <form class="mt-4" on:submit|preventDefault={handleSubmit}>
    <div class="row">

      <div class="col-lg">
        <div class="col mt-3">
          <div class="form-group mt-2">
            <label class="form-label" for="firstName">First Name</label>
            <input type="text" class="form-control" bind:value={form.firstName} />
          </div>
        </div>

        <div class="col mt-3">
          <label class="form-label" for="lastName">Last Name</label>
          <input type="text" class="form-control" bind:value={form.lastName} />
        </div>

      </div>

      <div class="col-lg">

        <div class="col mt-3">
          <label class="form-label" for="email">Email Address</label>
          <input type="text" class="form-control" bind:value={form.email} />
        </div>

        <div class="col mt-3">
          <label class="form-label" for="password">Password</label>
          <input type="password" class="form-control" bind:value={form.password} />
        </div>

      </div>

    </div>

    <div class="row row-cols-auto mt-4">
      <div class="col">
        <button type="submit" class="btn btn-primary">Sign Up</button>
      </div>
      <div class="col mt-1">
        <a href="/users/signin">Already have an Account? Sign In</a>
      </div>
    </div>
  </form>
  

</main>

<style>
  .intro {
    padding-top: 10px;
    color: #000;
    background-color: #f1f1f1;
    margin-left: 0px;
    margin-right: 0px;
  }
</style>
