<script lang="ts">
    import { onMount } from 'svelte'
    import page from 'page'
    import { userStore } from '../stores/UserStore'
    import { onSubscribe } from '../../../lib/helpers'
    import { alertStore } from '../../../common/alert/stores/AlertStore';
    import Loader from '../../../common/loader/components/Loader.svelte';

    let token
    onSubscribe(userStore, onConfirmSuccess, 'confirm.success')
    onSubscribe(userStore, onConfirmError, 'confirm.error')
    onSubscribe(userStore, onUserNotActive, 'signIn.userNotActive')

    onMount(() => {
      token = (new URLSearchParams(window.location.search)).get('token')
      if(token) userStore.confirm(token)
    })
  
    function onConfirmSuccess() {
        page('/users/signin')
        alertStore.add({ message: 'Email confirmed!' })
    }

    function onUserNotActive() {
        alertStore.add({ message: 'Seems like you have not validated your email! Don\'t worry we sent you a new one.\nPlease check your email.' })
    }

    function onConfirmError() {
      page('/users/signin')
      alertStore.add({ message: 'Seems like your email validation has expired!\nPlease signin and we will send you a new one!' })
    }
  
  </script>
  
  <main>
    {#if token}
      <Loader />
    {:else}
      <h1>Welcome to SEO Arcade!</h1>

      <div class="intro row row-cols-auto mt-4 mb-4">
        <div class="col-xl-9">
          <p>
            Just one more step.<br>
            A confirmation welcome email was sent to your inbox.<br> 
            Please follow the verification link within to continue.
          </p>
        </div>
      </div>
      <p>
        <small>
          Can't find the email? Intergalactic space aliens may have trapped it in your spam folder. Maybe take a peek in there.<br>
          If you cannot find it, <a href="/users/signin">signin</a> and we will send you a new one.<br>
          Otherwise, please contact our <a href="mailto:support@seoarcade.com">support@seoarcade.com</a><br>
        </small>
      </p>
    {/if}
  </main>

  <style>
    .intro {
      padding-top: 10px;
      color: #000;
      background-color: #f1f1f1;
      margin-left: 0px;
      margin-right: 0px;
    }
  </style>  
  
  