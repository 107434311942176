
<script lang="ts">
  import { seoRoiForecastStore } from '../stores/SeoRoiForecastStore'
  import Modal from '../../../common/modal/components/Modal.svelte'
  import Tooltip from '../../../common/tooltip/Tooltip.svelte'
  import { userStore } from '../../users/stores/UserStore'
  import { modalStore } from '../../../common/modal/stores/ModalStore'
  import { alertStore } from '../../../common/alert/stores/AlertStore'
  import Config from '../../../lib/Config'
  import { toDecimal, onSubscribe, debounce, formatCsv } from '../../../lib/helpers'

  const price = Number(Config.get('pricing.seoRoiForecast'))

  export let form = {
    keyword: '',
    domain: '',
    conversionRate: 0.00,
    seed: true,
    saleRate: 0.00,
    revenuePerSale: 0.00,
    device: 'desktop',
    locationName: 'United States',
    languageName: 'English'
  }

  export let isRerun = false

  let confirm = false
  let reruns = 0

  onSubscribe(seoRoiForecastStore, onGenerateSuccess, 'generate.success')

  function onGenerateSuccess() {
    resetForm()
    modalStore.hide()
    alertStore.add({ message: 'Generating report. This may take a minute or two. The table will auto refresh during this time.'})
  }

  /**
  * Handle Submit
  */
  function handleSubmit() {
    if(confirm) {
      confirm = false
      form.keyword = formatCsv(form.keyword)
      form.domain = formatCsv(form.domain)
      seoRoiForecastStore.generate(form)
    } else confirm = true

  }

  function handleCancel() {
    resetForm()
    modalStore.hide()
  }

  function resetForm() {
    form.keyword = ''
    form.domain = '',
    form.conversionRate = 0.00
    form.seed = true,
    form.saleRate = 0.00
    form.revenuePerSale = 0.00
    form.device = 'desktop'
    form.locationName = 'United States'
    form.languageName = 'English'
    isRerun = false
    confirm = false
    reruns = 0
  }

  function decimal(node, param) {
    function formDecimal(e) {
      form[param] = toDecimal(Number(e.target.value))
    }
    node.addEventListener('change', formDecimal)
    return { destroy: () => node.removeEventListener('change', formDecimal) }
  }

  function rerunsLeft(): number {
    return Number(Config.get('reruns.seoRoiForecast')) - seoRoiForecastStore.get().rerunTally.rerunTally
  }

  function keyword(node) {
    const handleKeyup = debounce((e) => form.keyword = e.target.value)
    node.addEventListener('keyup', handleKeyup)
    return { destroy: () => node.removeEventListener('keyup', handleKeyup) }
  }

  function domain(node) {
    const handleKeyup = debounce((e) => form.domain = e.target.value)
    node.addEventListener('keyup', handleKeyup)
    return { destroy: () => node.removeEventListener('keyup', handleKeyup) }
  }

  function onshow() {
    reruns = rerunsLeft()
  }

</script>

<Modal id="SeoRoiForecastGenerate" title="Generate SEO ROI Forecast" maxWidth="660px" onShow={onshow}>

  <form on:submit|preventDefault={handleSubmit}>

    <div class="row">
      <div class="col-12 form-group mt-2">
        <label class="col-10 form-label" for="keyword">Keyword(s) 
          <Tooltip>We need a single focus keyword to kick off the process. If you provide multiple different services, then you would want to run multiple reports. For example, 'Nashville metal roofing' vs 'Nashville Chimney Repair' would be best run as separate reports. Whereas 'Nashville roofer' vs 'Nashville roofing' will have very heavy overlap. Separate multiple keywords with commas. Maximum 5 seed keywords. Maximum 500 non-seed keywords. </Tooltip></label>
        {#if userStore.hasAuth('admin') }
          <label class="col form-label inline-label" for="seed">
            <input class="form-check-input" type="checkbox" bind:checked={form.seed} disabled={isRerun}/> Seed
            <Tooltip>Seeding will take just a few keywords, discover and collect additional keywords from ranking SERPs. If all the keywords are known, uncheck "seed", effectively bypassing additional SERPs keyword discoveries.</Tooltip></label>
        {/if}
      </div>
      <div class="col-12 form-group">
        <textarea disabled={isRerun} class="form-control" value={form.keyword} use:keyword placeholder="arcade games, classic arcade games, arcade video games" />
      </div>
    </div>

    <div class="row row-cols-auto">
      <div class="col-12 form-group mt-2">
        <label class="form-label" for="domain">Domain(s) <Tooltip>Optional domain(s) in which to compare results when supplying seeded keywords. Domains provided here will be displayed first and highlighted in the report's Discovered Competitors tab. Please leave off `www.` when comparing root level domains.</Tooltip></label>
        <textarea class="form-control" value={form.domain} use:domain placeholder="seoarcade.com, your-domain.com" />
      </div>
    </div>

    <div class="row row-cols-auto">

      <div class="col-6 form-group mt-3">
        <label class="form-label" for="conversionRate">Conversion Rate <Tooltip>Ideally, you will be able to get a specific conversion rate for your specific product or service associated with the focus keyword. If you need a stand-in rate, then 2.5% is a good generic organic traffic conversion rate to start with as a baseline. For eCommerce products, you would subtract your cart abandon rate from your conversion rate. Or you could ignore Cart Abandon and just input 100 for 100%.
          Typical sales teams taking qualified leads often report sales close rates in the 25% range.</Tooltip></label>
        <div class="input-group">
          <input type="number" placeholder="0.00" step="0.1" class="form-control" value={form.conversionRate} use:decimal={'conversionRate'} />
          <div class="input-group-append">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>

      <div class="col-6 form-group mt-3">
        <label class="form-label" for="saleRate">Sale Rate <Tooltip>Just because someone submits a contact form or demo request which would be counted as a 'Conversion' that does NOT mean that they are sold yet. Those are often referred to as 'MQL' or Marketing Qualified Lead. This field is to indicate how many of your 'Converversions' typically turn into actual sales.</Tooltip></label>
        <div class="input-group">
          <input type="number" placeholder="0.00" step="0.1" class="form-control" value={form.saleRate} use:decimal={'saleRate'} />
          <div class="input-group-append">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row row-cols-auto">
      <div class="col-6 form-group mt-3">
        <label class="form-label" for="revenuePerSale">Revenue Per Sale <Tooltip>When a site visitor for this keyword becomes a conversion, then a sale, typically what's the average $ monetary value that generates for the business? You can either use an 'average client lifetime value' if you have a recurring revenue product or just the initial signup/purchase amount. Just be sure to convey to your client which value you calculated.</Tooltip></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input type="number" placeholder="0.00" step="0.01" class="form-control" value={form.revenuePerSale} use:decimal={'revenuePerSale'} />
        </div>
      </div>

      <div class="col-6 form-group mt-3">
        <label class="form-label" for="device">Device <Tooltip>CTR device, desktop or mobile. Rankings results will be returned by device.</Tooltip></label>
        <div class="input-group">
          <select class="form-select form-select form-control" bind:value={form.device}>
              <option value="desktop">Desktop</option>
              <option value="mobile">Mobile</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row row-cols-auto">
      <div class="col-6 form-group mt-3">
        <label class="form-label" for="locationName">Location</label>
          <div class="input-group">
          <select class="form-select form-select form-control" bind:value={form.locationName}>
            {#each seoRoiForecastStore.getLocationNames() as name }
              <option value={name}>{name}</option>
            {/each}
          </select>
        </div>
      </div>
  
      <div class="col-6 form-group mt-3">
        <label class="form-label" for="languageName">Language <Tooltip>See location/language combination list. <a href="https://docs.google.com/spreadsheets/d/16cH38W812mDP2cD8Qj23UTpdPL6AY3BehBdG117APbU/edit?usp=sharing" target="_blank">SEO Location/Language List</a></Tooltip></label>
          <div class="input-group">
          <select class="form-select form-select form-control" bind:value={form.languageName}>
            {#each seoRoiForecastStore.getLanguageNames() as name }
              <option value={name}>{name}</option>
            {/each}
          </select>
        </div>
      </div>
    </div>

    <div class="row row-cols-auto mt-4">
      <div class="col">Note: Report generation can take up to 1-3 minutes, depending on the quantity and quality of the keyword(s). Table will auto refresh during this time. Once view your report in Google Sheets, click "Make a Copy" to be able to edit the data.</div>
    </div>
  
    <div class="row row-cols-auto mt-4">
      <div class="col">
        {#if confirm}<button type="button" class="btn btn-secondary" on:click={handleCancel}>Cancel</button>{/if}
        <button type="submit" class={confirm ? 'btn btn-success' : 'btn btn-primary'} >{#if confirm}Confirm?{:else}Generate{/if}</button>
      </div>
      <div class="col-6 mt-2">
        {#if isRerun }
          [{reruns} reruns remaining]
        {:else}
          [{price} coins]
        {/if}
      </div>
    </div>

  </form>
  
</Modal>