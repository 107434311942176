<script lang="ts">
  import { onMount } from 'svelte'
  import { fade } from 'svelte/transition';
  import { modalStore } from '../stores/ModalStore'
  import { alertStore } from '../../alert/stores/AlertStore'
  import { onSubscribe } from '../../../lib/helpers';

  interface IButton {
    onClick: () => void
    text: string
    type?: string
  }

  export let onShow = (options?: Record<string, any>) => {}
  export let onHide = (options?: Record<string, any>) => {}
  export let maxWidth: string = "600px" 
  export let id: string
  export let title: string = ''
  export let show: boolean = false
  export let zindex: number = 2000
  export let buttons: IButton[] = []

  onSubscribe(modalStore, showModal, `show.success.${id}`)
  onSubscribe(modalStore, hideModal, ['hide.success', `hide.success.${id}`])

  onMount(() => {
    const modal = modalStore.get()
    if(!modal.id) {
      const url = new URL(window.location.href)
      const openModal = url.searchParams.get('openModal')
      if(openModal) {
        modalStore.show(openModal)
        url.searchParams.delete('openModal')
        window.history.pushState(null, '', url.href)
      }
    }
  })

  function showModal () {
    const modal = modalStore.get()
    document.body.style.overflow = 'hidden'
    show = true
    zindex = modal.zindex

    onShow(modal.options)
  }

  function hideModal () {
    const modal = modalStore.get()

    // Add the scroll bars back in for the body
    document.body.style.overflow = 'auto'
    show = false
    zindex = modal.zindex

    // Remove all alerts
    alertStore.clear()
    modalStore.clear()
    onHide(modal.options)

  }

</script>

{#if show}
  <div id={id} class="modal" style="zindex: {zindex};" transition:fade tabIndex="-1">
    <div class="modal-dialog" style="width: 98%; max-width: {maxWidth};">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{title}</h5>
          <button type="button" on:click={hideModal} class="btn-close btn-small" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        {#if buttons.length}
        <div class="modal-footer">
          {#each buttons as button}
            <button type="button" on:click={button.onClick} class="btn btn-{button.type ||  'primary'}">{button.text}</button>
          {/each}
        </div>
        {/if}
      </div>
    </div>
  </div>
{/if}

<style>
  .modal {
    display: block;
  }

  .modal-header {
    padding: 1rem 2rem;
  }

  .modal-footer {
    padding: .75rem;
  }
</style>