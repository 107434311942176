<script lang="ts">
  import { alertStore } from '../stores/AlertStore'
  import { onSubscribe } from '../../../lib/helpers'

  let alerts = []

  onSubscribe(alertStore, onUpdateSuccess, ['add.success', 'cleared.success', 'delete.success'])

  function onUpdateSuccess() {
    alerts = alertStore.get().alerts
  }

  function handleDismiss(message) {
    alertStore.delete(message)
  }

</script>

<div id="alerts">
  {#each alerts as alert}
    <div  class={'alert alert-' + alert.type + ' alert-dismissible'} style="position:absolute; top: 30px; right: 200px" role="alert">
      <button type="button" class="btn-close" on:click={() => handleDismiss(alert.message)} ></button>
      {#if alert.title}
        <h4>{alert.title}</h4>
      {/if}
      {alert.message}
    </div>
  {/each}
</div>

<style>
  #alerts {
    position: fixed;
    top: 50px;
    right: 20px;
    z-index: 10055;
  }

  .alert {
    width: 300px;
    margin-top: 25px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  }
</style>