<script lang="ts">
  import Modal from '../modal/components/Modal.svelte'
  import { modalStore } from '../modal/stores/ModalStore'

  import { uuid } from '../../lib/helpers'

  const symbolMap = {
    info: 'ⓘ',
    popout: '◹'
  }

  export let symbol = 'info'
  const id = uuid()
  function handleClick() {
    modalStore.show(id)
  }
</script>

<Modal id={id}>
  <slot></slot>
</Modal>
<span on:click={handleClick}><small>{symbolMap[symbol]}</small></span>

<style>
  span {
    cursor: pointer;
    color: #1a84b0;
  }
</style>
