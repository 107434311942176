import page from 'page';
import Store from '../../../lib/Store';
class ModalStore extends Store {
    constructor() {
        super({ id: '', zindex: 2000, options: {} });
        page.exit(this.onPageExit.bind(this));
    }
    /**
     * On Page Exit
     */
    onPageExit(ctx, next) {
        this.clear();
        next();
    }
    show(id, options = {}) {
        if (this.get().id == id)
            return;
        this.set({ id, options, zindex: this.get().zindex + 1 }, `show.success.${id}`);
    }
    hide(id, options = {}) {
        if (this.get().id == id)
            return;
        if (id) {
            this.set({ id, options, zindex: this.get().zindex - 1 }, `hide.success.${id}`);
        }
        else {
            this.set({ id: '', zindex: 2000, options: {} }, 'hide.success');
        }
    }
    /**
   * Clear
   */
    clear() {
        this.set({ id: '', zindex: 2000, options: {} }, 'cleared.success');
    }
}
export const modalStore = new ModalStore();
