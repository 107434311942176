<script lang="ts">
  import { onMount } from 'svelte'
  import page from 'page'
  import { userStore } from '../stores/UserStore'

  
  const form = {
    email: ''
  }
  
  onMount(() => {
    if (userStore.get().self._id) page('/')
  })
  
  function handleSubmit(){
    userStore.forgotPassword(form)
  }
  
</script>
  
<main>
  <h1>Forgot Password</h1>
  <form class="mt-4" on:submit|preventDefault={handleSubmit}>
    <div class="row">

      <div class="col-lg">
        <div class="col-lg-6 mt-3">
          <div class="form-group mt-2">
            <label class="form-label" for="email">Email Address</label>
            <input type="text" class="form-control" bind:value={form.email} />
          </div>
        </div>

      </div>

    </div>

    <div class="row row-cols-auto mt-4">
      <div class="col">
        <button type="submit" class="btn btn-primary">Go</button>
      </div>
    </div>
  </form>

</main>