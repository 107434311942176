<script lang="ts">
  import type { IColumn } from './types'

  export let row: Record<string, any>
  export let cell: IColumn

</script>

{#if cell.component}
  <svelte:component this={cell.component} componentParams={cell.componentParams} row={row} cell={cell} label={cell.label || ''} />
{:else}
  <td class={cell.class || ''} id={cell.id} width={cell.width || ''}>
    <div class="thead">{cell.title}</div>
    {#if cell.callback}
      {cell.callback(row[cell.id])}
    {:else if row[cell.id] == null}
      --
    {:else}
      {#if cell.format}
        {#if cell.format == 'date'}
          {(new Date(row[cell.id])).toLocaleString()}
        {:else if cell.format == 'currency'}
          ${row[cell.id]}
        {:else if cell.format == 'percent'}
          {row[cell.id]}%
        {/if}
      {:else}
        {row[cell.id]}
      {/if}
    {/if}
  </td>
{/if}


<style>

  td {
    padding: 1rem .625rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.5s;
    border-top: 1px solid #ddd;
  }


  @media screen and (min-width: 39.9375em) {
    .thead {
      display: none;
    }
  }

  @media screen and (max-width: 39.9375em) {

    .thead {
      text-align: center;
    }
    td {
      display: block;
      width: 400px;
      text-align: center;
    }
  }

</style>
