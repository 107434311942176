import { events } from "./Events";
class Errors {
    add(errors) {
        const messages = [];
        errors.forEach(({ message }) => {
            if (messages.includes(message))
                return;
            else
                messages.push(message);
            switch (message) {
                case 'User not active.':
                    events.publish('userStore.active.error', { message });
                    break;
                case 'Session not active.':
                    events.publish('userStore.session.error', { message });
                    break;
                default:
                    events.publish('alertStore.error', { message });
                    break;
            }
        });
    }
}
export const errors = new Errors();
